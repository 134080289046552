import { createAsyncThunk } from '@reduxjs/toolkit';
import { PseudoFunctionBuilderOptions, UpdatePseudoFunctionRequest } from 'src/api/api';
import { RapydlyApi } from 'src/api/RapydlyApi';

export const fetchPseudoFunctionBuilderOptions = createAsyncThunk(
	'pseudoFunctions/get',
	async ({ client }: { client: RapydlyApi }) => {
		const response = await client.pseudoFunction_GetAll();
		return response;
	}
);

export const fetchPseudoFunctionBuilderSql = createAsyncThunk(
	'pseudoFunctions/getSql',
	async ({ client, options }: { client: RapydlyApi; options: PseudoFunctionBuilderOptions }) => {
		const response = await client.pseudoFunction_GetSqlFromOptions({
			pseudoFunctionBuilderOptions: options,
		});
		return response;
	}
);

export const updatePseudoFunctionBuilderOptions = createAsyncThunk(
	'pseudoFunctions/update',
	async ({
		newFunctionOption,
		client,
	}: {
		client: RapydlyApi;
		previousFunctionOption: UpdatePseudoFunctionRequest;
		newFunctionOption: UpdatePseudoFunctionRequest;
	}) => {
		const newOrUpdatedId = await client.pseudoFunction_Update(newFunctionOption);
		return { newOrUpdatedId };
	}
);

export const deletePseudoFunctionBuilderOptions = createAsyncThunk(
	'pseudoFunctions/delete',
	async ({ id, client }: { client: RapydlyApi; id: number }) => {
		const newOrUpdatedId = await client.pseudoFunction_Delete(id);
		return { newOrUpdatedId };
	}
);
