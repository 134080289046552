import { createSelector } from '@reduxjs/toolkit';

import { RapydlyCapabilities, RapydlyCapabilitiesTypes } from '../models/preference';
import { RootState } from '../store';
import { selectUserCapabilities } from './user';

const selectPreferencesSlice = (rootState: RootState) => rootState.preferencesSlice;

const selectAppPreferencesAuthorizationMappings = createSelector(
	selectPreferencesSlice,
	(a) => a.data.appPreferences.authorizationMappings
);

const createCapabilitySelector = (key: RapydlyCapabilitiesTypes) =>
	createSelector(
		selectUserCapabilities,
		selectAppPreferencesAuthorizationMappings,
		(userCapabilities, authorizationMappings) => {
			const mappedValue = authorizationMappings[key];
			if (mappedValue === undefined) {
				return true;
			} else if (!userCapabilities) {
				return false;
			} else return userCapabilities.includes(mappedValue);
		}
	);

export const selectCapabilityCanAccessAdminAreas = createCapabilitySelector(
	RapydlyCapabilities.canAccessAdminAreas
);

export const selectCapabilityCanAccessOrganisationAdminAreas = createCapabilitySelector(
	RapydlyCapabilities.canAccessOrganisationAdminAreas
);

export const selectCapabilityCanRunActions = createCapabilitySelector(
	RapydlyCapabilities.canRunActions
);

export const selectCapabilityCanViewOverview = createCapabilitySelector(
	RapydlyCapabilities.canViewOverview
);

export const selectCapabilityCanViewAllFunctions = createCapabilitySelector(
	RapydlyCapabilities.canViewAllFunctions
);

export const selectCapabilityCanViewAllStoredProcedures = createCapabilitySelector(
	RapydlyCapabilities.canViewAllStoredProcedures
);

export const selectCapabilityCanViewAllTables = createCapabilitySelector(
	RapydlyCapabilities.canViewAllTables
);

export const selectCapabilityCanViewAllViews = createCapabilitySelector(
	RapydlyCapabilities.canViewAllViews
);

export const selectCapabilityCanViewAnyFromTableDefinition = createSelector(
	selectCapabilityCanViewAllFunctions,
	selectCapabilityCanViewAllStoredProcedures,
	selectCapabilityCanViewAllTables,
	selectCapabilityCanViewAllViews,
	(f, sp, t, v) => {
		return f || sp || t || v;
	}
);
