import { Chip, CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRapydlyApi } from 'src/api/RapydlyApiContext';
import { showSnackbar } from 'src/redux/reducers/snackbar';
import {
	selectCustomPagePreferences,
	selectViewPreferences,
} from 'src/redux/selectors/preferences';
import { useAppDispatch } from 'src/redux/store';

export const NumberBadge = ({
	schema,
	name,
	customId,
	max = 9,
}: {
	schema: string;
	name: string;
	customId?: number;
	max?: number;
}) => {
	const viewPreferences = useSelector(selectViewPreferences(schema, name));
	const customPagePreferences = useSelector(selectCustomPagePreferences(customId));
	const [isLoading, setIsLoading] = React.useState(true);

	const [currentCount, setCurrentCount] = React.useState<number>(0);

	const dispatch = useAppDispatch();
	const client = useRapydlyApi();

	useEffect(() => {
		const fn = async () => {
			try {
				if (viewPreferences?.showNumberBadge) {
					const rows = await client.views_Get({
						schema,
						view: name,
					});
					setCurrentCount(rows.length);
				} else if (customPagePreferences?.showNumberBadge) {
					const rows = await client.functions_Run({
						schema,
						name,
						inputs: {},
					});
					setCurrentCount(rows.length);
				}
			} catch (e) {
				dispatch(showSnackbar({ message: 'Failed to get counts.', type: 'error' }));
			} finally {
				setIsLoading(false);
			}
		};

		fn();
	}, []);

	if (!viewPreferences?.showNumberBadge && !customPagePreferences?.showNumberBadge) return <></>;

	if (isLoading) return <CircularProgress size={12} color="primary" />;

	return (
		<Chip
			size="small"
			color="secondary"
			label={
				<Typography variant="caption">
					{currentCount > max ? `${max}+` : currentCount}
				</Typography>
			}
		/>
	);
};
