import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

export const fetchDatabases = createAsyncThunk('databases/fetch', async (client: RapydlyApi) => {
	const response = await client.databases_Get();
	return response;
});

export const selectDatabase = createAsyncThunk(
	'databases/select',
	async (selectedDatabase: string | null) => {
		return selectedDatabase;
	}
);
