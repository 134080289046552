import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { NodeDefinition } from 'src/api/api';
import { selectCapabilityCanAccessAdminAreas } from 'src/redux/selectors/capabilities';
import { selectTableDefinitions } from 'src/redux/selectors/databaseDefinition';

const TableAdminPopup = React.lazy(() => import('./TableAdminPopup'));

export const TableAdminPopupSearch = () => {
	const [selectedTable, setSelectedTable] = React.useState<NodeDefinition | null>(null);
	const tableDefinitions = useSelector(selectTableDefinitions);
	const isAdmin = useSelector(selectCapabilityCanAccessAdminAreas);

	if (!isAdmin) return <></>;

	return (
		<>
			<Autocomplete
				id="search-input-for-table-admin"
				value={selectedTable}
				placeholder={`Open a table's admin settings`}
				onChange={(a, newValue) => {
					setSelectedTable(newValue);
				}}
				fullWidth
				options={tableDefinitions.sort((a, b) =>
					`${a.category}.${a.name}` > `${b.category}.${b.name}` ? 1 : -1
				)}
				getOptionLabel={(a) => `${a.category}.${a.name}`}
				renderInput={(params) => <TextField variant="outlined" {...params} />}
			/>
			{selectedTable && (
				<TableAdminPopup
					schema={selectedTable.category!}
					name={selectedTable.name!}
					close={() => setSelectedTable(null)}
				/>
			)}
		</>
	);
};
