import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRapydlyApi } from 'src/api/RapydlyApiContext';
import { selectAppPreferences } from 'src/redux/selectors/preferences';
import { useAppDispatch } from 'src/redux/store';
import { fetchAccountAlerts } from 'src/redux/thunks/accountAlerts';

export const AccountAlertsLoader = () => {
	const dispatch = useAppDispatch();
	const api = useRapydlyApi();
	const {
		accountAlertsDataSourceSchema,
		accountAlertsDataSourceName,
		enableAccountAlerts,
		accountAlertsTitleField,
		accountAlertsDescriptionField,
		accountAlertsTypeField,
		accountAlertsActionTextField,
		accountAlertsActionUrlField,
	} = useSelector(selectAppPreferences);

	React.useEffect(() => {
		if (
			!enableAccountAlerts ||
			!accountAlertsDataSourceSchema ||
			!accountAlertsDataSourceName ||
			!accountAlertsTitleField ||
			!accountAlertsDescriptionField ||
			!accountAlertsTypeField ||
			!accountAlertsActionTextField ||
			!accountAlertsActionUrlField
		) {
			return;
		}

		const fn = async () => {
			try {
				await dispatch(
					// @ts-ignore
					fetchAccountAlerts({
						client: api,
						schema: accountAlertsDataSourceSchema,
						name: accountAlertsDataSourceName,
						titleField: accountAlertsTitleField,
						descriptionField: accountAlertsDescriptionField,
						typeField: accountAlertsTypeField,
						actionText: accountAlertsActionTextField,
						actionUrl: accountAlertsActionUrlField,
					})
				);
			} catch (e) {}
		};
		fn();
	}, [
		api,
		dispatch,
		enableAccountAlerts,
		accountAlertsDataSourceSchema,
		accountAlertsDataSourceName,
		accountAlertsTitleField,
		accountAlertsDescriptionField,
		accountAlertsTypeField,
		accountAlertsActionTextField,
		accountAlertsActionUrlField,
	]);

	return <></>;
};
