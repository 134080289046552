import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRapydlyApiNoTenant } from 'src/api/RapydlyApiNoTenantContext';
import { LoadingModal } from 'src/components/loading/loadingModal/LoadingModal';
import {
	selectDatabaseDefinitionError,
	selectDatabaseDefinitionIsLoading,
	selectDatabaseName,
} from 'src/redux/selectors/databaseDefinition';
import { useAppDispatch } from 'src/redux/store';
import { fetchDatabaseDefinition } from 'src/redux/thunks/databaseDefinition';

import { GenericErrorPage } from './GenericErrorPage';

export const DatabaseDefinitionLoadingWrapper: React.FC = ({ children }) => {
	const dispatch = useAppDispatch();
	const api = useRapydlyApiNoTenant();

	const databaseDefinitionError = useSelector(selectDatabaseDefinitionError);
	const databaseDefinitionIsLoading = useSelector(selectDatabaseDefinitionIsLoading);

	const databaseDefinition = useSelector(selectDatabaseName);

	React.useEffect(() => {
		const fn = async () => {
			try {
				// @ts-ignore
				dispatch(fetchDatabaseDefinition(api));
			} catch (e) {}
		};
		fn();
	}, [api, dispatch]);

	if (databaseDefinitionIsLoading) {
		return <LoadingModal text={`Loading Data`} />;
	} else if (databaseDefinitionError) {
		return <GenericErrorPage />;
	} else if (!databaseDefinition) {
		return <></>;
	} else {
		return <>{children}</>;
	}
};
