import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DatabaseRelationshipOverview } from 'src/api/api';

import { ISlice } from '../models';
import { selectDatabase } from '../thunks/databases';
import { fetchDatabaseRelationshipOverview } from '../thunks/table';

export interface DataFlow {
	tableId: string;
	selectedItem?: Record<string, any>;
	relationshipToPrevious: 'child' | 'parent' | null;
}

interface DataFlowOverview {
	dataflows: DataFlow[];
}

const initialState: ISlice<DatabaseRelationshipOverview | null> & {
	dataFlowOverview: DataFlowOverview;
} = {
	isLoading: false,
	data: null,
	dataFlowOverview: {
		dataflows: [],
	},
};

const slice = createSlice({
	name: 'databaseRelationshipOverview',
	initialState,
	reducers: {
		addDataFlowRoot(state, action: PayloadAction<string>) {
			state.dataFlowOverview.dataflows = [
				{ tableId: action.payload, relationshipToPrevious: null },
			];
		},
		addDataFlow(state, action: PayloadAction<DataFlow>) {
			state.dataFlowOverview.dataflows.push(action.payload);
		},
		addDataFlowSelectedIds(
			state,
			action: PayloadAction<{ index: number; ids: Record<string, any> }>
		) {
			state.dataFlowOverview.dataflows[action.payload.index].selectedItem =
				action.payload.ids;
		},
		removeDatafromFromIndex(state, action: PayloadAction<number>) {
			state.dataFlowOverview.dataflows = state.dataFlowOverview.dataflows.slice(
				0,
				action.payload
			);
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchDatabaseRelationshipOverview.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchDatabaseRelationshipOverview.rejected, (state) => {
				state.isLoading = false;
				state.data = {};
			})
			.addCase(fetchDatabaseRelationshipOverview.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(selectDatabase.fulfilled, (state) => {
				state.isLoading = false;
				state.data = null;
				state.dataFlowOverview = { dataflows: [] };
			}),
});

export const { addDataFlowRoot, addDataFlow, removeDatafromFromIndex, addDataFlowSelectedIds } =
	slice.actions;

export default slice.reducer;
