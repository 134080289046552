import * as React from 'react';
import { useHistory } from 'react-router-dom';

import SimpleModal from '../modals/simpleModal/SimpleModal';

const ImLost: React.FC = () => {
	const history = useHistory();
	return (
		<SimpleModal
			fieldName="imLost"
			open
			close={() => history.push(`/`)}
			title={"Oh no, you're lost."}
			description={
				"Looks like you're on a page that doesn't exist anymore. Not to worry though, the name of the data you were looking for has probably just changed. Just head back to the home page and look for your data source."
			}
			primaryText={'Go back home'}
			primaryPress={() => history.push(`/`)}
		/>
	);
};

export default ImLost;
