import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

export const fetchTenants = createAsyncThunk(
	'tenants/fetch',
	async ({ api, schema, name }: { api: RapydlyApi; schema: string; name: string }) => {
		const response = await api.functions_Run({
			schema,
			name,
			inputs: {},
		});
		return response;
	}
);
