import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

interface SchemaTable {
	schema: string;
	table: string;
	fields?: string[];
	showColumnName?: boolean;
}

export const fetchDropdownValues = createAsyncThunk(
	'dropdownValues/get',
	async ({
		schema,
		table,
		fields,
		showColumnName,
		client,
	}: SchemaTable & { client: RapydlyApi }) => {
		const response = await client.tables_GetDropdownValues(
			schema,
			table,
			fields,
			showColumnName
		);
		return response;
	}
);
