import { TextField } from '@mui/material';
import * as React from 'react';

interface TextBoxProps {
	label: string;
	value?: string;
	defaultValue?: string;
	onChange: (newValue: string) => void;
	fieldName: string;
	disabled?: boolean;
	required?: boolean;
	errored?: boolean;
	helperText?: string;
	placeholder?: string;
	rows?: number;
	onBlur?: () => void;
	autoFocus?: boolean;
	className?: string;
	inputClassName?: string;
}

const TextFieldInput: React.FC<TextBoxProps> = (props) => {
	const {
		label,
		value,
		onChange,
		fieldName,
		disabled,
		errored,
		required,
		helperText,
		placeholder,
		rows,
		onBlur,
		autoFocus,
		className,
		inputClassName,
	} = props;
	return (
		<TextField
			inputProps={{
				className: inputClassName,
			}}
			required={required}
			className={className}
			id={`textfield-input-${fieldName}`}
			autoComplete="off"
			autoCorrect="off"
			label={label}
			autoFocus={autoFocus}
			placeholder={placeholder ?? label}
			InputLabelProps={{ shrink: !!value }}
			value={value}
			onChange={(a): void => onChange(a.target.value)}
			variant="outlined"
			fullWidth
			margin="none"
			error={errored}
			helperText={helperText}
			aria-readonly={disabled}
			disabled={disabled}
			rows={rows}
			multiline={!!rows}
			onBlur={() => onBlur && onBlur()}
		/>
	);
};

export default TextFieldInput;
