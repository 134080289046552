import { Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRapydlyApiNoTenantOrDatabase } from 'src/api/RapydlyApiNoTenantOrDatabaseContext';
import { LoadingModal } from 'src/components/loading/loadingModal/LoadingModal';
import {
	selectDatabases,
	selectDatabasesError,
	selectDatabasesIsLoading,
} from 'src/redux/selectors/databases';
import { selectSelectedDatabaseId } from 'src/redux/selectors/preferencesLocal';
import { fetchDatabases } from 'src/redux/thunks/databases';

import { BackgroundUi } from './BackgroundUi';
import { DatabasePicker } from './DatabasePicker';
import { GenericErrorPage } from './GenericErrorPage';

export const DatabaseLoadingWrapper: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const api = useRapydlyApiNoTenantOrDatabase();
	const databases = useSelector(selectDatabases);
	const databasesIsLoading = useSelector(selectDatabasesIsLoading);
	const databasesError = useSelector(selectDatabasesError);
	const selectedDatabaseId = useSelector(selectSelectedDatabaseId);

	React.useEffect(() => {
		try {
			dispatch(fetchDatabases(api));
		} catch (e) {}
	}, [api, dispatch]);

	if (databasesIsLoading) {
		return <LoadingModal text="Loading Data" />;
	} else if (databasesError) {
		return <GenericErrorPage />;
	} else if (!databases) {
		return <LoadingModal text="Loading Data" />;
	} else if (databases.length === 0) {
		return (
			<BackgroundUi>
				<Typography variant="body1">
					You do not have access to any applications or databases.
				</Typography>
			</BackgroundUi>
		);
	} else if (!selectedDatabaseId || !databases.some((d) => d.id === selectedDatabaseId)) {
		return <DatabasePicker />;
	} else {
		return <>{children}</>;
	}
};
