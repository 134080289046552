import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { Databases } from '../models/databases';
import { fetchDatabases } from '../thunks/databases';

const initialState: ISlice<Databases> = {
	isLoading: true,
	data: {
		databases: null,
	},
};

const slice = createSlice({
	name: 'databases',
	initialState,
	reducers: {
		// updateName(state, action: PayloadAction<string>) {
		// 	state.firstName = action.payload;
		// },
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchDatabases.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchDatabases.rejected, (state, action) => {
				state.isLoading = false;
				if (action.error) {
					state.error = 'An error occured getting databases.';
				}
				state.data.databases = [];
			})
			.addCase(fetchDatabases.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data.databases = action.payload;
			}),
});

// export const {} = slice.actions;

export default slice.reducer;
