import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { CurrentRow } from '../models/currentRow';
import { fetchRow } from '../thunks/currentRow';
import { addColumnToRow, updateRow } from '../thunks/table';

interface EditRowPageState {
	currentRowToUpdate?: Record<string, any>;
	isCurrentTableDataValid: boolean;
}

const initialState: ISlice<CurrentRow | null> & EditRowPageState = {
	isLoading: false,
	data: null,
	isCurrentTableDataValid: true,
};

const slice = createSlice({
	name: 'currentRow',
	initialState,
	reducers: {
		setCurrentRowToUpdate(state, action: PayloadAction<Record<string, any> | undefined>) {
			state.currentRowToUpdate = action.payload;
		},
		setCurrentRowIsValid(state, action: PayloadAction<boolean>) {
			state.isCurrentTableDataValid = action.payload;
		},
		setCurrentRowToUpdateClean(state) {
			state.currentRowToUpdate = state.data ?? undefined;
		},
		setCurrentRowToUpdateDirty(state) {
			state.currentRowToUpdate = { ...(state.data ?? {}) };
		},
		updateCurrentRowToUpdate(state, action: PayloadAction<{ key: string; value?: any }>) {
			if (!state.currentRowToUpdate) return;

			state.currentRowToUpdate[action.payload.key] = action.payload.value;
		},
		fetchRowPending(state) {
			state.isLoading = true;
			state.data = null;
			state.currentRowToUpdate = undefined;
			state.isCurrentTableDataValid = true;
		},
		fetchRowRejected(state) {
			state.isLoading = false;
			if (state.data) {
				state.data = null;
			}
		},
		fetchRowFulfilled(state, action: PayloadAction<CurrentRow | null>) {
			state.isLoading = false;
			state.data = action.payload;
			state.currentRowToUpdate = state.data ?? undefined;
			state.isCurrentTableDataValid = true;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchRow.pending, (state) => {
				state.isLoading = true;
				state.data = null;
				state.currentRowToUpdate = undefined;
				state.isCurrentTableDataValid = true;
			})
			.addCase(fetchRow.rejected, (state) => {
				state.isLoading = false;
				if (state.data) {
					state.data = null;
				}
			})
			.addCase(fetchRow.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
				state.currentRowToUpdate = state.data;
				state.isCurrentTableDataValid = true;
			})

			.addCase(updateRow.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateRow.rejected, (state) => {
				state.isLoading = false;
			})
			.addCase(updateRow.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = { ...state.data, ...action.meta.arg.valuesToUpdate };
				state.currentRowToUpdate = state.data;
				state.isCurrentTableDataValid = true;
			})

			.addCase(addColumnToRow.fulfilled, (state, action) => {
				state.data = {
					...state.data,
					[action.meta.arg.request.newColumnName!]: action.meta.arg.request.defaultValue,
				};
				state.currentRowToUpdate = state.data;
				state.isCurrentTableDataValid = true;
			}),
});

export const {
	setCurrentRowToUpdate,
	updateCurrentRowToUpdate,
	setCurrentRowIsValid,
	setCurrentRowToUpdateClean,
	setCurrentRowToUpdateDirty,
	fetchRowPending,
	fetchRowRejected,
	fetchRowFulfilled,
} = slice.actions;

export default slice.reducer;
