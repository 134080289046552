import * as React from 'react';

import { AppLogo } from './AppLogo';
import { useStyles } from './DrawerContents';

export const AppLogoSection = () => {
	const classes = useStyles();

	return (
		<div className={classes.logoWrapper}>
			<AppLogo />
		</div>
	);
};
