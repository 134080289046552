import { MenuItem, Select, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { setSelectedTenant } from 'src/redux/reducers/tenants';
import { selectAppPreferences } from 'src/redux/selectors/preferences';
import { selectSelectedTenant, selectTenants } from 'src/redux/selectors/tenants';
import { useAppDispatch } from 'src/redux/store';

export const TenantPicker = () => {
	const tenants = useSelector(selectTenants);
	const appPreferences = useSelector(selectAppPreferences);
	const dispatch = useAppDispatch();
	const selectedTenant = useSelector(selectSelectedTenant);

	const tenantIdField = appPreferences.multiTenancyIdField;
	const tenantDispayField = appPreferences.multiTenancyDisplayField;

	if (
		!appPreferences.enableMultiTenancy ||
		!tenantIdField ||
		!tenantDispayField ||
		!selectedTenant ||
		!tenants.length
	)
		return <></>;

	if (tenants.length === 1) {
		return (
			<div style={{ marginBottom: 8 }}>
				<Typography>{selectedTenant[tenantDispayField]}</Typography>
			</div>
		);
	}

	return (
		<div style={{ marginBottom: 8 }}>
			<Select
				id={`select-tenantSelector`}
				variant="outlined"
				color="primary"
				fullWidth
				value={selectedTenant[tenantIdField] ?? null}
				onChange={async (e) => {
					const newValue = e.target.value;
					if (newValue && typeof newValue === 'number') {
						const newTenant = tenants.find((a) => a[tenantIdField] === newValue);
						if (newTenant) {
							dispatch(setSelectedTenant(newTenant));
						}
					}
				}}
			>
				{!!tenantIdField &&
					!!tenantDispayField &&
					(tenants ?? []).map((v) => (
						<MenuItem key={v[tenantIdField]} value={v[tenantIdField]}>
							{v[tenantDispayField]}
						</MenuItem>
					))}
			</Select>
		</div>
	);
};
