import React from 'react';
import ReactDOM from 'react-dom';

import { DefaultThemeProvider } from './pages/AppTheming';
import ReduxRoot from './pages/ReduxRoot';
import { RHelmet } from './RHelmet';
import * as serviceWorker from './serviceWorker';

const Root = () => {
	return (
		<DefaultThemeProvider>
			<RHelmet />
			<ReduxRoot />
		</DefaultThemeProvider>
	);
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
