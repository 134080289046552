import { Alert, Snackbar } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSnackbar } from 'src/redux/selectors/snackbar';

const SnackbarWrapper: React.FC = () => {
	const [open, setOpen] = React.useState(false);
	const snackbarModel = useSelector(selectSnackbar);

	React.useEffect(() => {
		setOpen(false);
		setOpen(true);
	}, [snackbarModel]);

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	if (!snackbarModel || !snackbarModel.type || !snackbarModel.message) {
		return <></>;
	}

	return (
		<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} sx={{ minWidth: 300 }}>
			<Alert
				onClose={handleClose}
				severity={snackbarModel.type}
				variant="filled"
				sx={{ width: '100%' }}
			>
				{snackbarModel.message}
			</Alert>
		</Snackbar>
	);
};

export default SnackbarWrapper;
