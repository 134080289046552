import { ColumnDefinition, NodeDefinition, Relationship, XY } from 'src/api/api';

import { ISlice } from '.';

export interface DataSeries {
	id: number;
	dataSource?: string;
	xAxis?: string;
	yAxis?: string;
	yAxisColumn?: string;
	inputs?: Record<string, string>;
}

export interface DashboardCard extends DataSeries {
	cardType?: string;
	showXAxisTitle?: boolean;
	showYAxisTitle?: boolean;
	showYValuesAsMoney?: boolean;
	showXAxisValues?: boolean;
	showYAxisValues?: boolean;
	series?: DataSeries[];
	title?: string;
}

export interface UserPreferences {
	dashboardCards: DashboardCard[];
}

export interface TenantPreferences {
	secondaryColor?: string;
	primaryColor?: string;
	appLogo?: string;
}

interface UserPreferencesCache {
	dashboardCardGraphData: Record<string, ISlice<XY[]> & { seriesData: XY[][] }>;
}

interface SchemaDatasourceField {
	datasource?: string;
	column?: string;
}

export interface CustomPageDefinition {
	pageDescription?: string;
	dataSource?: string;
	id: number;
	pageInUrl?: string;
	pageGrouping?: string;
}

export enum RapydlyCapabilities {
	'canAccessAdminAreas' = 'canAccessAdminAreas',
	'canRunActions' = 'canRunActions',
	'canRunReports' = 'canRunReports',
	'canViewOverview' = 'canViewOverview',
	'canEditData' = 'canEditData',
	'canSwapRunAsGroup' = 'canSwapRunAsGroup',

	'canViewAllStoredProcedures' = 'canViewAllStoredProcedures',
	'canViewAllFunctions' = 'canViewAllFunctions',
	'canViewAllTables' = 'canViewAllTables',
	'canViewAllViews' = 'canViewAllViews',

	'canAccessOrganisationAdminAreas' = 'canAccessOrganisationAdminAreas',
	'canAccessDepartmentAdminAreas' = 'canAccessDepartmentAdminAreas',
}

export type RapydlyCapabilitiesTypes = keyof typeof RapydlyCapabilities;

interface AppPreferencesAuthorizationMappings {
	[RapydlyCapabilities.canAccessAdminAreas]?: number;
	[RapydlyCapabilities.canRunActions]?: number;
	[RapydlyCapabilities.canRunReports]?: number;
	[RapydlyCapabilities.canViewOverview]?: number;
	[RapydlyCapabilities.canEditData]?: number;
	[RapydlyCapabilities.canSwapRunAsGroup]?: number;
	[RapydlyCapabilities.canViewAllStoredProcedures]?: number;
	[RapydlyCapabilities.canViewAllFunctions]?: number;
	[RapydlyCapabilities.canViewAllTables]?: number;
	[RapydlyCapabilities.canViewAllViews]?: number;
	[RapydlyCapabilities.canAccessOrganisationAdminAreas]?: number;
	[RapydlyCapabilities.canAccessDepartmentAdminAreas]?: number;
}

export interface AppPreferences {
	rapydlyFunctionPreferences?: RapydlyFunctionPreferences;
	reportsEnabledCheckDataSourceSchema?: string;
	reportsEnabledCheckDataSourceName?: string;
	reportsEnabledCheckTruthyField?: string;
	reportsEnabledCheckMessageField?: string;

	organisationCustomisationEnabledCheckDataSourceSchema?: string;
	organisationCustomisationEnabledCheckDataSourceName?: string;
	organisationCustomisationEnabledCheckTruthyField?: string;
	organisationCustomisationEnabledCheckMessageField?: string;

	enableAccountAlerts?: boolean;
	accountAlertsDataSourceName?: string;
	accountAlertsDataSourceSchema?: string;
	accountAlertsTitleField?: string;
	accountAlertsDescriptionField?: string;
	accountAlertsTypeField?: string;
	accountAlertsActionTextField?: string;
	accountAlertsActionUrlField?: string;

	getAdditionalExternalIdsForUserDatasource?: string;
	getAdditionalExternalIdsForUserField?: string;
	multiTenancyCreateNewDataSourceMappings?: Record<
		string,
		{ label?: string; helperText?: string; placeholder?: string }
	>;
	multiTenancyDataSourceSchema?: string;
	multiTenancyDataSourceName?: string;
	multiTenancyCreateNewDataSourceSchema?: string;
	multiTenancyCreateNewDataSourceName?: string;
	multiTenancyDisplayField?: string;
	multiTenancyIdField?: string;
	enableMultiTenancy?: boolean;
	appLogo?: string;
	authorizationCapabilitiesDatasourceColumnForDisplay?: string;
	authorizationCapabilitiesForUserDatasourceColumn?: string;
	authorizationCapabilitiesForUserDatasourceSchemaAndName?: string;
	authorizationCapabilitiesDatasourceColumn?: string;
	authorizationCapabilitiesDatasourceSchemaAndName?: string;
	reportsDataSourcesToShow?: string[];
	pseudoTables?: PseudoTable[];
	disableManualEditsSitewide?: boolean;
	customPages?: CustomPageDefinition[];
	tablesToHide?: string[];
	viewsToHide?: string[];
	storedProceduresToHide?: string[];
	functionsToHide?: string[];
	schemaDisplayNames?: Record<string, string>;
	autoGenerateFieldsWithCurrentUsername?: SchemaDatasourceField[];
	autoGenerateFieldsWithCurrentUsernameBlanketRule?: string[];
	autoGenerateFieldsWithCurrentTenantBlanketRule?: string[];
	autoGenerateFieldsWithCurrentUserGroupsBlanketRule?: string[];
	autoGenerateFieldsWithCurrentUserGroups?: SchemaDatasourceField[];
	primaryColor?: string;
	secondaryColor?: string;
	hidePrimaryKeys?: boolean;
	disableManualDeletionsSitewide?: boolean;
	disableManualAdditionsSitewide?: boolean;
	authorizationMappings: AppPreferencesAuthorizationMappings;
}

export interface RapydlyFunctionPreferences {
	getCurrentUserFunctionId?: number;
	auditTableDetails?: AuditTableDetails;
}

export interface AuditTableDetails {
	schema?: string;
	table?: string;

	fieldUsername?: string;
	fieldSource?: string;
	fieldAffectedTable?: string;
	fieldAffectedRow?: string;
	fieldField?: string;
	fieldValue?: string;
	fieldDetails?: string;
	fieldTenantId?: string;
}

export interface PseudoTable extends NodeDefinition {
	id: number;
	fields?: (ColumnDefinition & { id: number })[];
	foreignKeys?: (Relationship & { id: number })[];
}

export interface TablePreferences {
	description?: string;
	clearCacheUsersTenants?: boolean;
	clearCacheUsersCapabilities?: boolean;
	enabledCheckMessageField?: string;
	enabledCheckTruthyField?: string;
	enabledCheckDataSourceName?: string;
	enabledCheckDataSourceSchema?: string;
	backendRestrictedCapabilities?: number[];
	columnOrder?: string[];
	columnsToHideWhenNew?: string[];
	datasourceOverride?: string;
	datasourceOverrideIdMappings?: Record<string, string>;
	dropdownDatasourceOverrideDisplayField?: string;
	tableInfoTiles: TableInfoTile[];
	columnsToHideOnMobile?: string[];
	columnsToHideIfFalsey?: string[];
	allowColumGrouping?: boolean;
	largeTextColumns?: string[];
	barcodeColumns?: string[];
	customSaveNewEndpoint?: string;
	customSaveNewEndpointMappings?: Record<string, string>;
	confirmationDialogThenBackToStoredProcedureAction?: Record<
		string,
		ConfirmationDialogThenBackToStoredProcedureAction
	>;
	areRowsSelectable?: boolean;
	showFilterOnTable?: boolean;
	customSaveEditEndpoint?: string;
	customSaveEditEndpointMappings?: Record<string, string>;
	createNewDataSource?: string;
	createNewDataSourceMappings?: Record<string, string>;
	createNewDataSourceHideRows?: string[];
	showNumberBadge?: boolean;
	goToTable?: string;
	goToTableIds?: Record<string, string>;
	canCreateTheseOnTheMainPage?: boolean;
	tablesToAllowCreatonOf?: string[];
	tablesToAllowCreatonOfViaPopup?: string[];
	relationshipsToHide?: string[];
	displayName?: string;
	columnsToShow?: string[];
	restrictPageToCapabilities?: number[];
	columnsToShowInDetailsPanel?: string[];
	fileColumns?: Record<string, FileColumnDef>;
	columnDisplayNames?: Record<string, string>;
	relationshipDisplayNames?: Record<string, string>;
	dropdownLabelColumns?: string[];
	showColumnNameonDropdown?: boolean;
	selectFromVewPostBackToStoredProcedureAction?: Record<
		string,
		SelectFromVewPostBackToStoredProcedureAction
	>;
	buildFromNewItemPopupPostBackToStoredProcedureAction?: Record<
		string,
		BuildFromNewItemPopupPostBackToStoredProcedureAction
	>;
	relationshipDatasourceSwaps?: Record<string, RelationshipDatasourceSwaps>;
	tableValidations?: Record<string, TableValidation>;
}

export interface RelationshipDatasourceSwaps {
	mappings?: Record<string, string>;
	dataSource?: string;
	relationshipName?: string;
	columnsToHide?: string[];
	goToTable?: string;
	goToTableIds?: Record<string, string>;
	endpointForDeletions?: string;
	endpointForDeletionsMappings?: Record<string, string>;
}

export interface TableValidation {
	dataSource?: string;
	dataSourceInputs?: Record<string, string>;
	validationName?: string;
	validationSuccessMessage?: string;
	validationFailMessage?: string;
	operator?: '<=' | '>=' | '==';
	valueToCompare?: string;
}

export interface TableInfoTile {
	id: number;
	displayDataSource?: string;
	displayDataSourceInputs?: Record<string, string>;
	detailsDataSource?: string;
	detailsDataSourceInputs?: Record<string, string>;
	infoTileHeading?: string;
	infoTileDetails?: string;
}

export interface CommonAction {
	dataSourcePostInputsToIgnore?: string[];
	id: string;
	title?: string;
	fulfilledText: string;
	failedText: string;
	dataSourcePost?: string;
	fields?: InputTypeForPost[];
	showOnlyIfConditions?: CommonActionShowOnlyIfState[];
}

export type Operator = '===';

export interface CommonActionShowOnlyIfState {
	id: number;
	enabled?: boolean;
	column?: string;
	operator?: Operator;
	compareColumnToThis?: any;
}

export interface ConfirmationDialogThenBackToStoredProcedureAction extends CommonAction {
	description?: string;
}

export interface BuildFromNewItemPopupPostBackToStoredProcedureAction extends CommonAction {
	valueToInjectToNewRowMapping?: Record<string, string>;
	schemaInput?: string;
	tableInput?: string;
	inputColumnsToIgnore?: string[];
}

export interface SelectFromVewPostBackToStoredProcedureAction extends CommonAction {
	columnsToHide?: string[];
	displayDatasourceAsTable?: boolean;
	dataSourceInput?: string;
	dataSourceInputMappings?: Record<string, string>;
	isMultiSelect: boolean;
	templateString?: string;
}

export interface InputTypeForPost {
	inputField?: string;
	outputField?: string;
	isFromCurrentTable?: boolean;
}

export interface FileColumnDef {
	columnName: string;
	path: string;
}

export interface PreferencesSlice {
	appPreferences: AppPreferences;
	userPreferences: UserPreferences;
	tenantPreferences: TenantPreferences;
	tablePreferences: Record<string, TablePreferences>;
	userPreferencesCache: UserPreferencesCache;
}
