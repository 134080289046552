import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

interface SchemaName {
	schema: string;
	name: string;
	idField: string;
	displayField: string;
}

export const fetchCapabilities = createAsyncThunk(
	'capabilities/fetch',
	async ({
		schema,
		name,
		client,
		idField,
		displayField,
	}: SchemaName & { client: RapydlyApi }) => {
		const response = await client.functions_Run({
			schema: schema,
			name: name,
			inputs: {},
		});

		if (!response || !Array.isArray(response) || !response.length) {
			return [];
		} else {
			return response.map((r) => ({
				id: r[idField!] as number,
				label: r[displayField!] as string,
			}));
		}
	}
);
