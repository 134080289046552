import Keycloak from 'keycloak-js';

const uOptions: Keycloak.KeycloakConfig = {
	clientId: 'fe-localhost',
	realm: 'Rapydly',
	url: 'https://auth-uat.rapydly.com/auth',
};

const pOptions: Keycloak.KeycloakConfig = {
	clientId: process.env.KeycloakClientId || 'fe-production',
	realm: 'Rapydly',
	url: process.env.KeycloakUrl || 'https://auth.rapydly.com/auth',
};

const href = window?.location?.href?.toLowerCase();

const keycloak = Keycloak(
	['uat', 'localhost'].some((a) => href?.includes(a)) ? uOptions : pOptions
);

export default keycloak;
