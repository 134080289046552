import HomeIcon from '@mui/icons-material/Home';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useStyles } from './DrawerContents';

export const GoToHomeButton = () => {
	const { pathname } = useLocation();
	const classes = useStyles();
	const history = useHistory();

	return (
		<List
			className={classNames(
				classes.listItem,
				pathname === '/' ? classes.selected : undefined
			)}
		>
			<ListItem id={`listItemButton-home`} button onClick={(): void => history.push('/')}>
				<ListItemIcon className={classes.icon}>
					<HomeIcon color="inherit" />
				</ListItemIcon>
				<ListItemText primary="Home" />
			</ListItem>
		</List>
	);
};
