import { v4 as uuidv4 } from 'uuid';

import {
	AddColumnToTableRequest,
	AddTableRowRequest,
	ApiException,
	Client,
	FrontEndErrorRequest,
	GetRowsFromForeignKeysRequest,
	GetSqlPseudoFunctionRequest,
	GetTableRequest,
	GetTableRowRequest,
	GetValuesForGraphRequest,
	GetViewRequest,
	IClient,
	NetworkGraphRequest,
	RunFunctionRequest,
	RunStoredProcedureRequest,
	UpdateColumnOnTableRequest,
	UpdatePreferencesForUserRequest,
	UpdatePreferencesRequest,
	UpdatePseudoFunctionRequest,
	UpdateTableRowRequest,
	UpdateTableStructureDetailRequest,
} from './api';
const feSessionId = uuidv4();

export class RapydlyApi {
	baseUrl: string;
	dbguid: string;
	feSessionId: string;
	alias?: string;
	tenant?: number;
	client: IClient;
	onUnauthorized: () => Promise<void>;

	constructor(
		baseUrl: string,
		dbguid: string,
		onUnauthorized: () => Promise<void>,
		alias?: string,
		getAuthToken?: () => string,
		tenant?: number
	) {
		this.feSessionId = feSessionId;
		this.baseUrl = baseUrl;
		this.dbguid = dbguid;
		this.tenant = tenant;
		this.alias = alias;
		this.onUnauthorized = onUnauthorized;
		this.client = new Client({ getAuthToken }, baseUrl);
	}

	async logoutIf401<T>(promise: Promise<T>) {
		try {
			return await Promise.resolve(promise);
		} catch (e) {
			const error = e as ApiException;
			if (error.status === 401) {
				await Promise.resolve(this.onUnauthorized());
			}
			return await Promise.reject(e);
		}
	}

	getDbguid() {
		return this.dbguid;
	}

	views_GetDefinition(category: string, node: string) {
		return this.logoutIf401(
			this.client.views_GetDefinition(
				category,
				node,
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}
	tables_GetRowsFromForeignKeys(request: GetRowsFromForeignKeysRequest) {
		return this.logoutIf401(
			this.client.tables_GetRowsFromForeignKeys(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	views_Get(request: GetViewRequest) {
		return this.logoutIf401(
			this.client.views_Get(this.dbguid, this.tenant, this.feSessionId, this.alias, request)
		);
	}
	admin_UserOverview() {
		return this.logoutIf401(
			this.client.admin_UserOverview(this.dbguid, this.tenant, this.feSessionId, this.alias)
		);
	}
	tables_GetDatabaseRelationshipOverview() {
		return this.logoutIf401(
			this.client.tables_GetDatabaseRelationshipOverview(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}
	tables_UpdateColumn(request: UpdateColumnOnTableRequest) {
		return this.logoutIf401(
			this.client.tables_UpdateColumn(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	tables_AddColumn(request: AddColumnToTableRequest) {
		return this.logoutIf401(
			this.client.tables_AddColumn(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	tables_AddRow(request: AddTableRowRequest) {
		return this.logoutIf401(
			this.client.tables_AddRow(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	tables_UpdateRow(request: UpdateTableRowRequest) {
		return this.logoutIf401(
			this.client.tables_UpdateRow(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	tables_DeleteRow(request: GetTableRowRequest) {
		return this.logoutIf401(
			this.client.tables_DeleteRow(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	tables_Get(request: GetTableRequest) {
		return this.logoutIf401(
			this.client.tables_Get(this.dbguid, this.tenant, this.feSessionId, this.alias, request)
		);
	}
	tables_GetValuesForGraph(request: GetValuesForGraphRequest[]) {
		return this.logoutIf401(
			this.client.tables_GetValuesForGraph(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	functions_RunForGraph(request: GetValuesForGraphRequest[]) {
		return this.logoutIf401(
			this.client.functions_RunForGraph(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	preferences_UpdateForUser(request: UpdatePreferencesForUserRequest) {
		return this.logoutIf401(
			this.client.preferences_UpdateForUser(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	preferences_UpdateForTenant(request: UpdatePreferencesForUserRequest) {
		return this.logoutIf401(
			this.client.preferences_UpdateForTenant(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	preferences_Update(request: UpdatePreferencesRequest) {
		return this.logoutIf401(
			this.client.preferences_Update(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	preferences_Get() {
		return this.logoutIf401(
			this.client.preferences_Get(this.dbguid, this.tenant, this.feSessionId, this.alias)
		);
	}
	preferences_GetTenant() {
		return this.logoutIf401(
			this.client.preferences_GetTenant(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}
	tables_GetDropdownValues(
		schema: string,
		table: string,
		fields: string[] | undefined,
		showColumnName: boolean | undefined
	) {
		return this.logoutIf401(
			this.client.tables_GetDropdownValues(
				schema,
				table,
				fields,
				showColumnName,
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}
	databases_Get() {
		return this.logoutIf401(
			this.client.databases_Get(this.dbguid, this.tenant, this.feSessionId, this.alias)
		);
	}
	tables_GetDatabaseDefinition() {
		return this.logoutIf401(
			this.client.tables_GetDatabaseDefinition(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}
	tables_GetDatabaseDefinitionRefreshCache() {
		return this.logoutIf401(
			this.client.tables_GetDatabaseDefinitionRefreshCache(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}
	tables_GetRow(request: GetTableRowRequest): any {
		return this.logoutIf401(
			this.client.tables_GetRow(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	storedProcedures_Run(request: RunStoredProcedureRequest): any {
		return this.logoutIf401(
			this.client.storedProcedures_Run(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	functions_Run(request: RunFunctionRequest): any {
		return this.logoutIf401(
			this.client.functions_Run(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
	files_Upload(body: any[], folderPath: string): any {
		return this.logoutIf401(
			this.client.files_Upload(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				folderPath,
				body
			)
		);
	}
	file_Delete(filePath: string) {
		return this.logoutIf401(
			this.client.file_Delete(this.dbguid, this.tenant, this.feSessionId, this.alias, {
				filePath,
			})
		);
	}
	file_Get(path: string) {
		return this.logoutIf401(
			this.client.file_Get(
				path,
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			) as any as Promise<Blob>
		);
	}
	tables_GetNetworkGraph(request: NetworkGraphRequest) {
		return this.logoutIf401(
			this.client.tables_GetNetworkGraph(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}

	tableStructureDetail_Get(schema: string, table: string) {
		return this.logoutIf401(
			this.client.tableStructureDetail_Get(
				schema,
				table,
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}

	tableStructureDetail_GetAll() {
		return this.logoutIf401(
			this.client.tableStructureDetail_GetAll(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}

	tableStructureDetail_Update(request: UpdateTableStructureDetailRequest) {
		return this.logoutIf401(
			this.client.tableStructureDetail_Update(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}

	pseudoFunction_GetAll() {
		return this.logoutIf401(
			this.client.pseudoFunction_GetAll(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}

	pseudoFunction_GetSqlFromId(pseudoFunctionBuilderOption: number) {
		return this.logoutIf401(
			this.client.pseudoFunction_GetSqlFromId(
				pseudoFunctionBuilderOption,
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}

	pseudoFunction_GetSqlFromOptions(request: GetSqlPseudoFunctionRequest) {
		return this.logoutIf401(
			this.client.pseudoFunction_GetSqlFromOptions(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}

	pseudoFunction_Update(request: UpdatePseudoFunctionRequest) {
		return this.logoutIf401(
			this.client.pseudoFunction_Update(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}

	pseudoFunction_Delete(id: number) {
		return this.logoutIf401(
			this.client.pseudoFunction_Delete(
				id,
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias
			)
		);
	}

	frontendAnalytics_LogError(request: FrontEndErrorRequest) {
		return this.logoutIf401(
			this.client.frontendAnalytics_LogError(
				this.dbguid,
				this.tenant,
				this.feSessionId,
				this.alias,
				request
			)
		);
	}
}
