import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectAppPreferencesLogo } from 'src/redux/selectors/preferences';

import { useStyles } from './DrawerContents';

export const AppLogo = ({ logoOverride }: { logoOverride?: string }) => {
	const logo = useSelector(selectAppPreferencesLogo);
	const classes = useStyles();

	return <img alt="App Logo" className={classes.logo} src={logoOverride ?? logo} />;
};
