import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Divider, IconButton, Menu, MenuItem, Theme, Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { toggleNavMenu } from 'src/redux/reducers/appState';
import { selectUserAuth } from 'src/redux/selectors/user';

import { TableAdminPopupSearch } from './tableAdmin/preferences/TableAdminPopupSearch';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			[theme.breakpoints.up('md')]: {
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(1),
			},
			background: '#fafafa00',
			backdropFilter: 'blur(10px)',
			border: 'none',
		},
		menuButton: {
			padding: theme.spacing(1),
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		title: {
			flexGrow: 1,
		},
		itemsContainer: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		profile: {
			background: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
		},
	})
);

export const TopAppBar = () => {
	const classes = useStyles();
	const { isMobile } = useDeviceInfo();
	const { nameOnly, username } = useSelector(selectUserAuth);
	const { keycloak } = useKeycloak();

	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar position="fixed" className={classes.root} elevation={0}>
			<Toolbar className={classes.itemsContainer}>
				<div>
					<IconButton
						id={`button-toggleTopAppBarNavMenu`}
						color="primary"
						aria-label="open drawer"
						edge="start"
						onClick={() => {
							dispatch(toggleNavMenu());
						}}
						className={classes.menuButton}
						size="large"
					>
						<MenuIcon />
					</IconButton>
				</div>
				<div style={{ flex: 1 }}></div>
				{!isMobile && (
					<div style={{ width: 300 }}>
						<TableAdminPopupSearch />
					</div>
				)}
				<div>
					<IconButton
						aria-label="profile"
						id={`button-openPRofileMenu`}
						onClick={handleMenu}
						aria-controls="menu-appbar"
						aria-haspopup="true"
						size="large"
					>
						<Avatar alt="profile" src="/broken-image.jpg" className={classes.profile}>
							{nameOnly?.substring(0, 1)?.toUpperCase()}
						</Avatar>
					</IconButton>
					<Menu
						id="topAppBarMenuParent"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={open}
						onClose={handleClose}
					>
						<MenuItem disabled>Welcome {nameOnly}</MenuItem>
						<MenuItem disabled>{username}</MenuItem>
						<Divider />
						<MenuItem onClick={() => keycloak.logout()}>Logout</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	);
};
