import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRapydlyApi } from 'src/api/RapydlyApiContext';
import { useRapydlyApiNoTenant } from 'src/api/RapydlyApiNoTenantContext';
import { LoadingModal } from 'src/components/loading/loadingModal/LoadingModal';
import { selectPreferencesError, selectPreferencesSuccess } from 'src/redux/selectors/preferences';
import { useAppDispatch } from 'src/redux/store';
import { fetchPreferences, fetchTenantPreferences } from 'src/redux/thunks/preferences';

import { GenericErrorPage } from './GenericErrorPage';

export const PreferencesLoadingWrapper: React.FC = ({ children }) => {
	const success = useSelector(selectPreferencesSuccess);
	const [isLoadingForTheFirstTime, setIsLoadingForTheFirstTime] = React.useState(true);
	const error = useSelector(selectPreferencesError);
	const dispatch = useAppDispatch();
	const api = useRapydlyApiNoTenant();

	useEffect(() => {
		const fn = async () => {
			try {
				// @ts-ignore
				await dispatch(fetchPreferences(api));
			} catch (e) {
			} finally {
				setIsLoadingForTheFirstTime(false);
			}
		};
		fn();
	}, [api, dispatch]);

	if (isLoadingForTheFirstTime) {
		return <LoadingModal text={`Loading App Settings`} />;
	} else if (error || !success) {
		return <GenericErrorPage />;
	} else {
		return <>{children}</>;
	}
};

export const OrganisationPreferencesLoadingWrapper: React.FC = ({ children }) => {
	const success = useSelector(selectPreferencesSuccess);
	const [isLoading, setIsLoading] = React.useState(true);
	const error = useSelector(selectPreferencesError);
	const dispatch = useAppDispatch();
	const api = useRapydlyApi();

	useEffect(() => {
		const fn = async () => {
			try {
				setIsLoading(true);
				// @ts-ignore
				await dispatch(fetchTenantPreferences(api));
			} catch (e) {
			} finally {
				setIsLoading(false);
			}
		};
		fn();
	}, [api, dispatch]);

	if (isLoading) {
		return <LoadingModal text={`Loading Your Settings`} />;
	} else if (error) {
		return <GenericErrorPage />;
	} else if (!success) {
		return <GenericErrorPage />;
	} else {
		return <>{children}</>;
	}
};
