import { Alert } from '@mui/material';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRapydlyApiNoTenant } from 'src/api/RapydlyApiNoTenantContext';
import TextButton from 'src/components/buttons/TextButton';
import TextFieldGroup from 'src/components/forms/textfieldGroup/TextFieldGroup';
import CheckBoxInput from 'src/components/inputs/CheckBoxInput';
import { LoadingModal } from 'src/components/loading/loadingModal/LoadingModal';
import { CurrentRow } from 'src/redux/models/currentRow';
import { showSnackbar } from 'src/redux/reducers/snackbar';
import { selectStoredProcedureDefinition } from 'src/redux/selectors/databaseDefinition';
import {
	selectAppPreferences,
	selectMultiTenantDatasource,
	selectMultiTenantEnabled,
} from 'src/redux/selectors/preferences';
import { selectSelectedDatabase } from 'src/redux/selectors/preferencesLocal';
import {
	selectSelectedTenant,
	selectTenants,
	selectTenantsError,
	selectTenantsIsLoading,
} from 'src/redux/selectors/tenants';
import { useAppDispatch } from 'src/redux/store';
import { fetchTenants } from 'src/redux/thunks/tenants';
import { camelToTitle } from 'src/utils/converters';
import { sqlTypesToColumnDefs } from 'src/utils/typesConverter';

import { TenantThemeProvider } from './AppTheming';
import { BackgroundUi } from './BackgroundUi';
import { DatabaseDefinitionLoadingWrapper } from './DatabaseDefinitionLoadingWrapper';
import { GenericErrorPage } from './GenericErrorPage';

interface IsLoadingProps {
	setIsLoading: (newIsLoading: boolean) => void;
	isLoading: boolean;
}

const TenantsBuilderForm = ({
	setIsLoading,
	isLoading,
	email,
}: IsLoadingProps & { email: string }) => {
	const appPreferences = useSelector(selectAppPreferences);
	const dispatch = useAppDispatch();
	const database = useSelector(selectSelectedDatabase);
	const api = useRapydlyApiNoTenant();
	const createNewTenenantDefinition = useSelector(
		selectStoredProcedureDefinition(
			appPreferences.multiTenancyCreateNewDataSourceSchema,
			appPreferences.multiTenancyCreateNewDataSourceName
		)
	);
	const mappings = appPreferences.multiTenancyCreateNewDataSourceMappings ?? {};
	const [currentTenantState, setCurrentTenantState] = React.useState<CurrentRow>({});
	const [agreesToTermsAndConditions, setAgreesToTermsAndConditions] =
		React.useState<boolean>(false);
	const [tabIndex, setTabIndex] = React.useState<number>(0);

	if (!createNewTenenantDefinition)
		return <Typography variant="body1">Looks like there's been a bit of a problem.</Typography>;

	const isFormValid =
		agreesToTermsAndConditions &&
		(createNewTenenantDefinition.inputs ?? []).every((a) => !!currentTenantState[a.name!]);

	const save = async () => {
		if (!isFormValid) return;
		setIsLoading(true);

		try {
			await api.storedProcedures_Run({
				schema: appPreferences.multiTenancyCreateNewDataSourceSchema,
				name: appPreferences.multiTenancyCreateNewDataSourceName,
				inputs: currentTenantState,
			});

			await dispatch(
				// @ts-ignore
				fetchTenants({
					api,
					schema: appPreferences.multiTenancyDataSourceSchema!,
					name: appPreferences.multiTenancyDataSourceName!,
				})
			);
		} catch (e) {
			dispatch(showSnackbar({ type: 'error', message: 'Looks like there was a problem.' }));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Grid container spacing={2} style={{ marginTop: 16 }}>
			<Grid item xs={12}>
				<Typography variant="body1" gutterBottom>
					Looks like you haven't been assigned to an organisation yet. What would you like
					to do?
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Tabs value={tabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)}>
					<Tab label="Join Existing" id="tab-JoinOrganisation" />
					<Tab label="Create New" id="tab-CreateOrganisation" />
				</Tabs>
			</Grid>
			<Grid item xs={12} style={{ padding: 32 }} container spacing={2}>
				{tabIndex === 0 ? (
					<>
						<Grid item xs={12}>
							<Typography variant="body1" gutterBottom>
								Looking to join an existing organisation?
							</Typography>
							<Typography variant="body1" gutterBottom>
								The Organisation Administrator will need to add your email address (
								{email}) to their organisation.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Alert severity="info">{`They can add you by going to Users > People > My Actions > Add New User `}</Alert>
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={12}>
							<Typography variant="body1" gutterBottom>
								Awesome, lets create a new organisation.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Alert severity="info">{`You will automatically be
								assigned our free tier subscription. You can upgrade at any time by emailing info@rapydly.com`}</Alert>
						</Grid>
						<Grid item xs={12}>
							<TextFieldGroup<CurrentRow>
								inputsOnly
								fullWidthInputs
								requiredColumns={[]}
								columns={
									[...(createNewTenenantDefinition.inputs ?? [])]
										?.sort((a, b) => (b.id ?? 0) - (a.id ?? 0))
										?.map((a) => ({
											field: a.name,
											title: mappings[a.name!].label || camelToTitle(a.name),
											placeholder: mappings[a.name!].placeholder,
											helperText: mappings[a.name!].helperText,
											type: sqlTypesToColumnDefs(a.dataType!),
											isBarcode: false,
											isLargeTextField: false,
										})) ?? []
								}
								model={currentTenantState}
								onChange={(a, b) =>
									setCurrentTenantState({ ...currentTenantState, [a]: b })
								}
							/>
						</Grid>
						<Grid item xs={12} container spacing={1}>
							<Grid item xs={12}>
								<CheckBoxInput
									fieldName="agreesToTermsAndConditions"
									value={agreesToTermsAndConditions}
									onChange={setAgreesToTermsAndConditions}
									label={
										"I agree to Rapydly's Terms, Conditions, and Privacy Policy"
									}
								/>
							</Grid>
							<Grid item>
								<a href={'https://rapydly.com/terms-and-conditions'}>
									Terms and Conditions
								</a>
							</Grid>
							<Grid item>
								<a href={'https://rapydly.com/privacy-policy/'}>Privacy Policy</a>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<TextButton
								fieldName="newTenantContinue"
								onClick={save}
								disabled={!isFormValid || isLoading}
								text={'Continue'}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

const TenantsBuilder = () => {
	const { keycloak } = useKeycloak();
	const database = useSelector(selectSelectedDatabase);
	const [isSaving, setIsSaving] = React.useState(false);

	if (!database) return <></>;

	return (
		<BackgroundUi isLoading={isSaving}>
			<Grid container>
				<Grid item xs={12}>
					<Typography gutterBottom variant="h4">
						Hi {keycloak?.tokenParsed?.given_name}
					</Typography>
					<Typography gutterBottom variant="h5">
						Welcome to Rapydly's {database.name}!
					</Typography>
					<DatabaseDefinitionLoadingWrapper>
						<TenantsBuilderForm
							isLoading={isSaving}
							setIsLoading={setIsSaving}
							email={keycloak?.tokenParsed?.email}
						/>
					</DatabaseDefinitionLoadingWrapper>
				</Grid>
			</Grid>
		</BackgroundUi>
	);
};

export const TenantsLoadingWrapper: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const api = useRapydlyApiNoTenant();
	const error = useSelector(selectTenantsError);
	const tenants = useSelector(selectTenants);
	const isLoading = useSelector(selectTenantsIsLoading);
	const selectedTenant = useSelector(selectSelectedTenant);
	const isEnabled = useSelector(selectMultiTenantEnabled);
	const { schema, name } = useSelector(selectMultiTenantDatasource);

	React.useEffect(() => {
		if (!name || !schema) return;
		try {
			dispatch(
				fetchTenants({
					api,
					name: name!,
					schema: schema!,
				})
			);
		} catch (e) {}
	}, [api, dispatch, name, schema]);

	if (selectedTenant) {
		return <TenantThemeProvider>{children}</TenantThemeProvider>;
	} else if (!isEnabled) {
		return <>{children}</>;
	} else if (isLoading) {
		return <LoadingModal text={`Loading Your Organisations`} />;
	} else if (error || !tenants) {
		return <GenericErrorPage />;
	} else if (tenants.length === 0) {
		return <TenantsBuilder />;
	} else {
		return <GenericErrorPage />;
	}
};
