import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRapydlyApi } from 'src/api/RapydlyApiContext';
import { LoadingModal } from 'src/components/loading/loadingModal/LoadingModal';
import {
	selectDatabaseDefinitionError,
	selectDatabaseName,
} from 'src/redux/selectors/databaseDefinition';
import { selectUserAuth, selectUserAuthError } from 'src/redux/selectors/user';
import { useAppDispatch } from 'src/redux/store';
import { fetchDatabaseDefinition } from 'src/redux/thunks/databaseDefinition';
import { fetchUserOverview } from 'src/redux/thunks/user';

import { GenericErrorPage } from './GenericErrorPage';

export const UserAndDatabaseDefinitionLoadingWrapper: React.FC = ({ children }) => {
	const dispatch = useAppDispatch();
	const api = useRapydlyApi();
	const [isLoadingForTheFirstTime, setIsLoadingForTheFirstTime] = React.useState(true);

	const userError = useSelector(selectUserAuthError);
	const databaseDefinitionError = useSelector(selectDatabaseDefinitionError);

	const user = useSelector(selectUserAuth);
	const databaseDefinition = useSelector(selectDatabaseName);

	React.useEffect(() => {
		const fn = async () => {
			try {
				// @ts-ignore
				const d1 = dispatch(fetchUserOverview(api));
				// @ts-ignore
				const d2 = dispatch(fetchDatabaseDefinition(api));
				await Promise.all([d1, d2]);
			} catch (e) {
			} finally {
				setIsLoadingForTheFirstTime(false);
			}
		};
		fn();
	}, [api, dispatch]);

	if (isLoadingForTheFirstTime) {
		return <LoadingModal text={`Loading Data`} />;
	} else if (userError || databaseDefinitionError) {
		return <GenericErrorPage />;
	} else if (!user || !databaseDefinition) {
		return <GenericErrorPage />;
	} else {
		return <>{children}</>;
	}
};
