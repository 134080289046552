export const convertToMoney = (value: number, significantDigits?: number) => {
	return new Intl.NumberFormat('en-NZ', {
		style: 'currency',
		currency: 'NZD',
		maximumSignificantDigits: significantDigits,
		minimumSignificantDigits: significantDigits,
	}).format(value);
};

export const camelToTitle = (camelCase?: string) =>
	!camelCase
		? ''
		: camelCase
				.replace(/([A-Z])/g, (match) => ` ${match}`)
				.replace(/^./, (match) => match?.toUpperCase())
				.trim();

export const sentenceToTitle = (sentenceCase?: string) =>
	!sentenceCase
		? ''
		: sentenceCase.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match?.toUpperCase()).trim();
