import { Typography } from '@mui/material';
import * as React from 'react';

import { BackgroundUi } from './BackgroundUi';

export const GenericErrorPage = () => {
	return (
		<BackgroundUi>
			<Typography variant="body1">Looks like there's been a problem.</Typography>
		</BackgroundUi>
	);
};
