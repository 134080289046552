import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { Capabilities } from '../models/capabilities';
import { fetchCapabilities } from '../thunks/capabilities';

const initialState: ISlice<Capabilities> = {
	isLoading: false,
	data: {
		capabilities: [],
	},
};

const slice = createSlice({
	name: 'views',
	initialState,
	reducers: {
		// updateName(state, action: PayloadAction<string>) {
		// 	state.firstName = action.payload;
		// },
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchCapabilities.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchCapabilities.rejected, (state, action) => {
				state.isLoading = false;
				state.data.capabilities = [];
			})
			.addCase(fetchCapabilities.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data.capabilities = action.payload;
			}),
});

// export const {} = slice.actions;

export default slice.reducer;
