import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterText } from 'src/redux/reducers/appState';
import { selectFilterText } from 'src/redux/selectors/appState';
import { selectCapabilityCanViewAnyFromTableDefinition } from 'src/redux/selectors/capabilities';

import TextFieldInput from '../inputs/TextFieldInput';

export const FilterTextTextBox = () => {
	const dispatch = useDispatch();
	const filterText = useSelector(selectFilterText);
	const canViewAnyTableDefs = useSelector(selectCapabilityCanViewAnyFromTableDefinition);

	if (!canViewAnyTableDefs) return <></>;

	return (
		<div style={{ padding: 16, paddingBottom: 0 }}>
			<TextFieldInput
				fieldName="drawerFilter"
				label="Filter"
				onChange={(newValue) => dispatch(setFilterText(newValue))}
				value={filterText}
			/>
		</div>
	);
};
