import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ProgrammabilityDef } from 'src/api/api';
import { selectFilterText } from 'src/redux/selectors/appState';
import {
	selectCapabilityCanAccessAdminAreas,
	selectCapabilityCanViewAllStoredProcedures,
} from 'src/redux/selectors/capabilities';
import { selectStoredProcedureDefinitions } from 'src/redux/selectors/databaseDefinition';
import {
	selectAppPreferences,
	selectStoredProcedurePreferencesAll,
} from 'src/redux/selectors/preferences';

import { useStyles } from './DrawerContents';

export const StoredProcedures = () => {
	const sps = useSelector(selectStoredProcedureDefinitions);
	const filterText = useSelector(selectFilterText);
	const appPreferences = useSelector(selectAppPreferences);
	const spPreferences = useSelector(selectStoredProcedurePreferencesAll);
	const canViewStoredProceduredList = useSelector(selectCapabilityCanViewAllStoredProcedures);
	const classes = useStyles();
	const { pathname } = useLocation();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const isAdmin = useSelector(selectCapabilityCanAccessAdminAreas);

	const spDict = (sps ?? [])
		.filter(
			(a) =>
				isAdmin ||
				appPreferences.storedProceduresToHide?.includes(`${a.schema}.${a.name}`) !== true
		)
		.reduce((all, a) => {
			all[a.schema!] = all[a.schema!] ? [...all[a.schema!], a] : [a];
			return all;
		}, {} as Record<string, ProgrammabilityDef[]>);

	const getPath = (p: ProgrammabilityDef) => `/sp/${p.schema!}/${p.name!}`;

	if (!canViewStoredProceduredList) return <></>;

	return Object.keys(spDict).length === 0 ? (
		<></>
	) : (
		<>
			<List>
				<ListItem id={`listItemButton-sp-hideshow`} button>
					<ListItemText className={classes.primary} onClick={() => setIsOpen((a) => !a)}>
						Stored Procedures{' '}
						{isOpen ? (
							<ExpandLessIcon style={{ float: 'right' }} />
						) : (
							<ExpandMoreIcon style={{ float: 'right' }} />
						)}
					</ListItemText>
				</ListItem>
				<Collapse in={isOpen}>
					{Object.keys(spDict)
						.sort()
						.map((schema) => (
							<div key={schema}>
								<ListSubheader color="inherit" className={classes.schema}>
									{appPreferences.schemaDisplayNames &&
									appPreferences.schemaDisplayNames[schema]
										? appPreferences.schemaDisplayNames[schema]
										: schema}
								</ListSubheader>
								{spDict[schema]
									.sort()
									.filter(
										(a) =>
											a &&
											a.name &&
											(!filterText ||
												a.name
													?.toLowerCase()
													.indexOf(filterText?.toLowerCase().trim()) > -1)
									)
									.map((p) => (
										<ListItem
											id={`listItemButton-sp-${schema}-${p.name}`}
											className={
												pathname.startsWith(getPath(p))
													? classes.selected
													: undefined
											}
											key={getPath(p)}
											button
											onClick={(): void => history.push(getPath(p))}
										>
											<ListItemText
												disableTypography
												className={classes.table}
												primary={
													spPreferences &&
													spPreferences[`sp_${schema}.sp_${p.name}`] &&
													spPreferences[`sp_${schema}.sp_${p.name}`]
														.displayName
														? spPreferences[`sp_${schema}.sp_${p.name}`]
																.displayName
														: p.name
												}
											/>
											{appPreferences.storedProceduresToHide?.includes(
												`${p.schema}.${p.name}`
											) === true && (
												<Tooltip title="This stored procedure is hidden for non-admin users">
													<ListItemIcon>
														<VisibilityOff />
													</ListItemIcon>
												</Tooltip>
											)}
										</ListItem>
									))}
							</div>
						))}
				</Collapse>
			</List>
			<Divider />
		</>
	);
};
