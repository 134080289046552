import MoreVert from '@mui/icons-material/MoreVert';
import {
	Container,
	Dialog,
	DialogActions,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	LinearProgress,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import * as React from 'react';
import TextButton from 'src/components/buttons/TextButton';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';

interface SimpleModalAction {
	label: string;
	action: () => void;
}

interface Props {
	fieldName: string;
	title: string;
	description?: string;
	open: boolean;

	primaryText: string;
	primaryPress: () => void;
	primaryDisabled?: boolean;

	secondaryText?: string;
	secondaryPress?: () => void;
	secondaryDisabled?: boolean;

	close: () => void;
	isLoading?: boolean;

	maxSize?: 'md' | 'sm' | 'lg';

	actions?: SimpleModalAction[];
}

const SimpleModal: React.FC<Props> = ({
	title,
	open,
	close,
	primaryText,
	primaryPress,
	secondaryText,
	secondaryPress,
	fieldName,
	maxSize,
	description,
	isLoading,
	children,
	primaryDisabled,
	secondaryDisabled,
	actions,
}) => {
	const { isMobile } = useDeviceInfo();
	const rootName = `simpleModal-${fieldName}`;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
        <Dialog
			open={open}
			fullScreen={isMobile}
			onClose={close}
			id={rootName}
			maxWidth={maxSize ?? 'md'}
			fullWidth
		>
			{isLoading && <LinearProgress />}
			<div style={{ display: 'flex' }}>
				<DialogTitle style={{ flex: 1 }}>{title}</DialogTitle>
				{actions?.length ? (
					<>
						<div>
							<IconButton
                                id={`SimpleModalMenuAnchor`}
                                color="primary"
                                aria-haspopup="true"
                                onClick={handleClick}
                                size="large">
								<MoreVert />
							</IconButton>
						</div>
						<Menu
							id="SimpleModalMenu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{actions.map((a) => (
								<MenuItem id={a.label} key={a.label} onClick={a.action}>
									<ListItemText>{a.label}</ListItemText>
								</MenuItem>
							))}
						</Menu>
					</>
				) : (
					<></>
				)}
			</div>
			<Container
				style={{
					paddingTop: 8,
					paddingBottom: 8,
					paddingLeft: isMobile ? 16 : 32,
					paddingRight: isMobile ? 16 : 32,
					flex: 1,
				}}
			>
				<Grid container spacing={2}>
					{!!description && (
						<Grid item xs={12}>
							<DialogContentText>{description}</DialogContentText>
						</Grid>
					)}
					{!!children && (
						<Grid item xs={12}>
							{children}
						</Grid>
					)}
				</Grid>
			</Container>
			<DialogActions>
				{!!secondaryText && !!secondaryPress && (
					<TextButton
						fieldName={`${rootName}Secondary`}
						onClick={secondaryPress}
						text={secondaryText}
						disabled={isLoading || secondaryDisabled}
						textOnly
					/>
				)}
				<TextButton
					disabled={isLoading || primaryDisabled}
					fieldName={`${rootName}Primary`}
					onClick={primaryPress}
					text={primaryText}
				/>
			</DialogActions>
		</Dialog>
    );
};
export default SimpleModal;
