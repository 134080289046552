import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const selectUserSlice = (rootState: RootState) => rootState.userSlice;

export const selectUserAuth = createSelector(selectUserSlice, (a) => a.data);
export const selectUserAuthError = createSelector(selectUserSlice, (a) => a.error);

export const selectUserRunningAs = createSelector(selectUserAuth, (a) => a.viewAs);

export const selectUserCapabilities = createSelector(selectUserAuth, (a) => a.capabilities);
