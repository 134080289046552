import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { StoredProcedureds } from '../models/storedProcedures';
import { runStoredProcedure } from '../thunks/storedProcedures';

const initialState: ISlice<StoredProcedureds> = {
	isLoading: false,
	data: {},
};

const slice = createSlice({
	name: 'storedProcedures',
	initialState,
	reducers: {
		updateStoredProcedureInput(
			state,
			action: PayloadAction<{
				schema: string;
				spName: string;
				inputName: string;
				value: string;
			}>
		) {
			if (!state.data[`${action.payload.schema}.${action.payload.spName}`]) {
				state.data[`${action.payload.schema}.${action.payload.spName}`] = {
					didError: false,
					output: null,
					input: {
						[action.payload.inputName]: action.payload.value,
					},
				};
			} else if (!state.data[`${action.payload.schema}.${action.payload.spName}`].input) {
				state.data[`${action.payload.schema}.${action.payload.spName}`].input = {
					[action.payload.inputName]: action.payload.value,
				};
			} else {
				state.data[`${action.payload.schema}.${action.payload.spName}`].input = {
					...state.data[`${action.payload.schema}.${action.payload.spName}`].input,
					[action.payload.inputName]: action.payload.value,
				};
			}
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(runStoredProcedure.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(runStoredProcedure.rejected, (state, action) => {
				state.isLoading = false;

				if (
					!state.data[`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`]
				) {
					state.data[
						`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
					] = {
						didError: false,
						output: null,
						input: {},
					};
				}

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].output = null;

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].didError = true;
			})
			.addCase(runStoredProcedure.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!Array.isArray(action.payload)) {
					return;
				}

				if (
					!state.data[`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`]
				) {
					state.data[
						`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
					] = {
						didError: false,
						output: null,
						input: {},
					};
				}

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].output = action.payload.map((a) => {
					return Object.keys(a).reduce((all, key) => {
						all[key] = Object.keys(a[key] ?? {}).length === 0 ? null : a[key];
						return all;
					}, {} as Record<string, any>);
				});

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].didError = false;
			}),
});

export const { updateStoredProcedureInput } = slice.actions;

export default slice.reducer;
