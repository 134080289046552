import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

interface SchemaView {
	schema: string;
	view: string;
}

export const fetchView = createAsyncThunk(
	'view/fetch',
	async ({ schema, view, client }: SchemaView & { client: RapydlyApi }) => {
		const response = await client.views_Get({ schema, view });
		return response;
	}
);
