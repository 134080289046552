import { Button, Link } from '@mui/material';

interface TextButtonProps {
	fieldName: string;
	text: string;
	onClick: () => void;
	disabled?: boolean;
	textOnly?: boolean;
	fullWidth?: boolean;
	className?: string;
}

const TextButton = ({
	fieldName,
	text,
	onClick,
	disabled,
	textOnly,
	fullWidth,
	className,
}: TextButtonProps) => {
	return (
		<Button
			id={`button-${fieldName}`}
			onClick={onClick}
			color="primary"
			disabled={disabled}
			variant={textOnly ? 'outlined' : 'contained'}
			fullWidth={fullWidth}
			className={className}
		>
			{text}
		</Button>
	);
};

export const TextButtonNewTab = ({
	fieldName,
	text,
	href,
}: {
	fieldName: string;
	text: string;
	href: string;
}) => {
	return (
		<Link id={`link-${fieldName}`} href={href} target="_blank">
			<Button id={`button-${fieldName}`} color="primary">
				{text}
			</Button>
		</Link>
	);
};

export default TextButton;
