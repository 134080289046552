import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRapydlyApi } from 'src/api/RapydlyApiContext';
import { selectCapabilityCanAccessAdminAreas } from 'src/redux/selectors/capabilities';
import {
	selectApplicationCapabilities,
	selectAppPreferences,
} from 'src/redux/selectors/preferences';
import { useAppDispatch } from 'src/redux/store';
import { runFunction } from 'src/redux/thunks/functions';

export const AppCapabilitiesLoader = () => {
	const dispatch = useAppDispatch();
	const api = useRapydlyApi();
	const {
		authorizationCapabilitiesDatasourceColumn,
		authorizationCapabilitiesDatasourceSchemaAndName,
	} = useSelector(selectAppPreferences);
	const allCapabilities = useSelector(selectApplicationCapabilities);
	const isAdmin = useSelector(selectCapabilityCanAccessAdminAreas);

	React.useEffect(() => {
		if (
			!isAdmin ||
			!authorizationCapabilitiesDatasourceColumn ||
			!authorizationCapabilitiesDatasourceSchemaAndName ||
			(allCapabilities && allCapabilities.length > 0)
		) {
			return;
		}

		const fn = async () => {
			const parts = authorizationCapabilitiesDatasourceSchemaAndName.split('.');
			try {
				await dispatch(
					// @ts-ignore
					runFunction({
						client: api,
						request: {
							schema: parts[0],
							name: parts[1],
							inputs: {},
						},
					})
				);
			} catch (e) {}
		};
		fn();
	}, [
		api,
		dispatch,
		authorizationCapabilitiesDatasourceSchemaAndName,
		authorizationCapabilitiesDatasourceColumn,
		allCapabilities,
		isAdmin,
	]);

	return <></>;
};
