import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { Tables } from '../models/tables';
import { deleteRow, fetchTable } from '../thunks/table';

const initialState: ISlice<Tables> = {
	isLoading: false,
	data: {},
};

const slice = createSlice({
	name: 'tables',
	initialState,
	reducers: {
		setTableRowsLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
		setTableRows(
			state,
			action: PayloadAction<{ schema: string; table: string; rows: Record<string, string>[] }>
		) {
			state.data[`${action.payload.schema}.${action.payload.table}`] = action.payload.rows;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchTable.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchTable.rejected, (state) => {
				state.isLoading = false;
				state.data = {};
			})
			.addCase(fetchTable.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = {
					[`${action.meta.arg.request.schema}.${action.meta.arg.request.table}`]:
						action.payload.rows ?? [],
				};
			})

			.addCase(deleteRow.pending, (state, action) => {
				state.isLoading = true;
				const newArray = state.data[`${action.meta.arg.schema}.${action.meta.arg.table}`];
				newArray.splice(action.meta.arg.index, 1);
				state.data[`${action.meta.arg.schema}.${action.meta.arg.table}`] = newArray;
			})
			.addCase(deleteRow.rejected, (state, action) => {
				state.isLoading = false;
				const newArray = state.data[`${action.meta.arg.schema}.${action.meta.arg.table}`];
				newArray.splice(action.meta.arg.index, 0, action.meta.arg.row);
				state.data[`${action.meta.arg.schema}.${action.meta.arg.table}`] = newArray;
			})
			.addCase(deleteRow.fulfilled, (state) => {
				state.isLoading = false;
			}),
});

export const { setTableRows, setTableRowsLoading } = slice.actions;

export default slice.reducer;
