import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { PreferencesLocal } from '../models/preferencesLocal';
import { fetchDatabases, selectDatabase } from '../thunks/databases';

const initialState: ISlice<PreferencesLocal> = {
	isLoading: false,
	data: {
		selectedDatabase: null,
	},
};

const slice = createSlice({
	name: 'preferencesLocal',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(selectDatabase.fulfilled, (state, action) => {
				state.data.selectedDatabase = action.payload;
			})
			.addCase(fetchDatabases.fulfilled, (state, action) => {
				if (!state.data.selectedDatabase && action.payload?.length === 1) {
					state.data.selectedDatabase = action.payload[0].id!;
				}
			}),
});

export default slice.reducer;
