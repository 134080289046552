import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { AppState } from '../models/appState';

const initialState: ISlice<AppState> = {
	isLoading: false,
	data: {
		isMobileOpen: false,
	},
};

const slice = createSlice({
	name: 'appState',
	initialState,
	reducers: {
		closeNavMenu(state) {
			state.data.isMobileOpen = false;
		},
		toggleNavMenu(state) {
			state.data.isMobileOpen = !state.data.isMobileOpen;
		},
		setFilterText(state, action: PayloadAction<string>) {
			state.data.filterText = action.payload;
		},
	},
	extraReducers: (builder) => builder,
	// .addCase(fetchDatabaseDefinition.pending, (state, action) => {
	// 	state.isLoading = true;
	// 	state.data = null;
	// })
});

export const { closeNavMenu, toggleNavMenu, setFilterText } = slice.actions;

export default slice.reducer;
