import { Alert } from '@mui/material';
import { Container } from '@mui/material';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useRapydlyApi } from 'src/api/RapydlyApiContext';

interface ServerLogChildErrorsProps {
	fallbackComponent?: React.ComponentType<FallbackProps>;
	onReset?: (...args: Array<unknown>) => void;
}

const DefaultFallbackComponent = () => {
	return (
		<Container maxWidth="md">
			<Alert severity="warning">Looks like there's been a problem diplaying this.</Alert>
		</Container>
	);
};

export const ServerLogChildErrors: React.FC<ServerLogChildErrorsProps> = ({
	children,
	fallbackComponent,
	onReset,
}) => {
	const api = useRapydlyApi();
	const onError = async (
		error: Error,
		info: {
			componentStack: string;
		}
	) => {
		await api.frontendAnalytics_LogError({
			error,
			componentStack: info.componentStack,
		});
	};

	return (
		<ErrorBoundary
			FallbackComponent={fallbackComponent ?? DefaultFallbackComponent}
			onReset={onReset}
			onError={onError}
		>
			{children}
		</ErrorBoundary>
	);
};
