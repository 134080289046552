/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';

import DrawerForDesktop from './DrawerForDesktop';
import DrawerForMobile from './DrawerForMobile';

const ResponsiveDrawer: React.FC = () => {
	const { isMobile } = useDeviceInfo();
	return isMobile ? <DrawerForMobile /> : <DrawerForDesktop />;
};

export default ResponsiveDrawer;
