import TimelineIcon from '@mui/icons-material/Timeline';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCapabilityCanViewOverview } from 'src/redux/selectors/capabilities';

import { useStyles } from './DrawerContents';

export const GoToOverviewButton = () => {
	const { pathname } = useLocation();
	const classes = useStyles();
	const history = useHistory();
	const canViewOverview = useSelector(selectCapabilityCanViewOverview);

	if (!canViewOverview) return <></>;

	return (
		<List
			className={classNames(
				classes.listItem,
				pathname === '/overview' ? classes.selected : undefined,
				classes.disableOnMobile
			)}
		>
			<ListItem
				id={`listItemButton-overview`}
				button
				onClick={(): void => history.push('/overview')}
			>
				<ListItemIcon className={classes.icon}>
					<TimelineIcon color="inherit" />
				</ListItemIcon>
				<ListItemText primary="Overview" />
			</ListItem>
		</List>
	);
};
