import { useMediaQuery, useTheme } from "@mui/material";

export const useDeviceInfo = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    const deviceInfo = {
        isLayoutStillLoading: !isXs && !isSm && !isMd && !isLg && !isXl,
        isMobile,
        isDesktop,
        sizes: {
            isXs,
            isSm,
            isMd,
            isLg,
            isXl
        }
    };

    return deviceInfo
}