import { Container, Grid, useTheme } from '@mui/material';
import * as React from 'react';
import { AppLogo } from 'src/components/drawer/AppLogo';
import { RCard } from 'src/components/wrappers/RCard';
import { DEFAULT_RAPYDLY_LOGO } from 'src/redux/selectors/preferences';

export const BackgroundUi: React.FC<{ isLoading?: boolean }> = ({ children, isLoading }) => {
	const theme = useTheme();

	return (
		<Grid
			container
			style={{
				paddingTop: theme.spacing(10),
				height: '100%',
				width: '100%',
			}}
		>
			<Grid item xs={12}>
				<Container maxWidth="md">
					<Grid container spacing={2}>
						<Grid item xs={12} style={{ maxWidth: 300 }}>
							<AppLogo logoOverride={DEFAULT_RAPYDLY_LOGO} />
						</Grid>
						<Grid item xs={12}>
							<RCard loading={isLoading}>
								<Grid
									container
									justifyContent="center"
									style={{
										backgroundColor: theme.palette.background.paper,
									}}
								>
									<Grid item xs={12}>
										{children}
									</Grid>
								</Grid>
							</RCard>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Grid>
	);
};
