import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { SelectItem, SelectItemSlice } from '../models/select';
import { fetchDatabaseDefinition } from '../thunks/databaseDefinition';
import { fetchDropdownValues } from '../thunks/dropdownValues';

const initialState: ISlice<SelectItemSlice> = {
	isLoading: false,
	data: {},
};

const slice = createSlice({
	name: 'dropdownValues',
	initialState,
	reducers: {
		addRowToDropdownInStateOnly(
			state,
			action: PayloadAction<{ schema: string; table: string; item: SelectItem }>
		) {
			const newArray = state.data[`${action.payload.schema}.${action.payload.table}`] ?? [];
			newArray.splice(0, 0, action.payload.item);
			state.data[`${action.payload.schema}.${action.payload.table}`] = newArray;
		},
		setDropdownRows(
			state,
			action: PayloadAction<{ schema: string; table: string; items: SelectItem[] }>
		) {
			state.data[`${action.payload.schema}.${action.payload.table}`] = action.payload.items;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchDatabaseDefinition.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = {};
			})
			.addCase(fetchDropdownValues.pending, (state, action) => {
				state.isLoading = true;
				state.data[`${action.meta.arg.schema}.${action.meta.arg.table}`] = [];
			})
			.addCase(fetchDropdownValues.rejected, (state, action) => {
				state.isLoading = false;
				state.data[`${action.meta.arg.schema}.${action.meta.arg.table}`] = [];
			})
			.addCase(fetchDropdownValues.fulfilled, (state, action) => {
				state.isLoading = false;
				// @ts-ignore
				state.data[`${action.meta.arg.schema}.${action.meta.arg.table}`] =
					action.payload.filter(
						(a) => !!a.label && !!a.value && a.value.every((b) => !!b.key && !!b.value)
					);
			}),
});

export const { addRowToDropdownInStateOnly, setDropdownRows } = slice.actions;

export default slice.reducer;
