import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { fetchTenants } from '../thunks/tenants';

const initialState: ISlice<{ rows: Record<string, any>[]; selectedTenant?: Record<string, any> }> =
	{
		isLoading: true,
		data: {
			rows: [],
		},
	};

const slice = createSlice({
	name: 'tenants',
	initialState,
	reducers: {
		setSelectedTenant(state, action: PayloadAction<Record<string, any>>) {
			state.data.selectedTenant = action.payload;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchTenants.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchTenants.rejected, (state, action) => {
				state.isLoading = false;
				if (action.error) {
					state.error = action.error.message;
				}
				state.data.rows = [];
			})
			.addCase(fetchTenants.fulfilled, (state, action) => {
				state.isLoading = false;
				if (action.payload && Array.isArray(action.payload)) {
					state.data.rows = action.payload;
					if (state.data.selectedTenant) {
						if (action.payload.length) {
							state.data.selectedTenant =
								action.payload.find(
									(t) =>
										JSON.stringify(t) ===
										JSON.stringify(state.data.selectedTenant)
								) ?? action.payload[0];
						} else {
							state.data.selectedTenant = undefined;
						}
					} else {
						if (action.payload.length) {
							state.data.selectedTenant = action.payload[0];
						}
					}
				}
			}),
});

export const { setSelectedTenant } = slice.actions;

export default slice.reducer;
