import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { selectDatabases } from './databases';

const selectPreferencesLocalSlice = (rootState: RootState) => rootState.preferencesLocalSlice;

export const selectSelectedDatabaseId = createSelector(
	selectPreferencesLocalSlice,
	(slice) => slice.data.selectedDatabase
);

export const selectSelectedDatabase = createSelector(
	selectSelectedDatabaseId,
	selectDatabases,
	(databaseId, databases) =>
		!!databases && databaseId ? databases.find((a) => a.id === databaseId) : undefined
);
