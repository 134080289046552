/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { closeNavMenu } from 'src/redux/reducers/appState';
import { selectIsNavBarOpen } from 'src/redux/selectors/appState';
import { useAppDispatch } from 'src/redux/store';

import { toggleNavMenu } from '../../redux/reducers/appState';
import { PageDrawer } from './DrawerContents';

export const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
			borderColor: theme.palette.grey[200],
			paddingLeft: 16,
			paddingRight: 16,
		},
	})
);

const DrawerForMobile: React.FC = () => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const isMobileOpen = useSelector(selectIsNavBarOpen);

	return (
		<>
			<CloseDrawOnLocationChange />
			<Drawer
				variant="temporary"
				open={isMobileOpen}
				onClose={(): void => {
					dispatch(toggleNavMenu());
				}}
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper,
				}}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				<PageDrawer />
			</Drawer>
		</>
	);
};

const CloseDrawOnLocationChange = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(closeNavMenu());
	}, [dispatch, location]);

	return <></>;
};

export default DrawerForMobile;
