import { List, ListItem, ListItemText, ListSubheader } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomPageDefinition } from 'src/redux/models/preference';
import {
	selectAllCustomPagePreferences,
	selectAppPreferences,
} from 'src/redux/selectors/preferences';

import { useStyles } from './DrawerContents';
import { NumberBadge } from './NumberBadge';

export const CustomPagesList = () => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const history = useHistory();
	const appPreferences = useSelector(selectAppPreferences);
	const allCustomPagePreferences = useSelector(selectAllCustomPagePreferences);

	const itemClick = history.push;

	const customPagesDict = React.useMemo(
		() =>
			appPreferences?.customPages?.reduce((all, page) => {
				if (!all[page.pageGrouping ?? 'My Pages']) {
					all[page.pageGrouping ?? 'My Pages'] = [];
				}

				all[page.pageGrouping ?? 'My Pages'].push(page);
				return all;
			}, {} as Record<string, CustomPageDefinition[]>),
		[appPreferences?.customPages]
	);
	return (
		<>
			{Object.keys(customPagesDict ?? {}).map((g) => (
				<List key={g} className={classes.listItem}>
					<ListSubheader color="inherit" className={classes.schema}>
						{g}
					</ListSubheader>
					{customPagesDict![g]
						.sort((a, b) =>
							(allCustomPagePreferences[`custom.${a.id}`]?.displayName ??
								a.pageInUrl!) >
							(allCustomPagePreferences[`custom.${b.id}`]?.displayName ??
								b.pageInUrl!)
								? 1
								: -1
						)
						.map((p) => (
							<ListItem
								id={`listItemButton-customPage-${p.pageInUrl}`}
								key={p.id}
								button
								onClick={(): void => itemClick(`/c/${p.pageInUrl}`)}
								className={classNames(
									classes.custompageItems,
									pathname.toLocaleLowerCase() ===
										`/c/${p.pageInUrl}`.toLocaleLowerCase()
										? classes.selectedItem
										: undefined
								)}
							>
								<ListItemText
									primary={
										allCustomPagePreferences[`custom.${p.id}`]?.displayName ??
										p.pageInUrl
									}
								/>
								{p.dataSource ? (
									<NumberBadge
										customId={p.id}
										schema={p.dataSource!.split('.')[0]}
										name={p.dataSource!.split('.')[1]}
									/>
								) : (
									<></>
								)}
							</ListItem>
						))}
				</List>
			))}
		</>
	);
};
