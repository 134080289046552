/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';

import { PageDrawer } from './DrawerContents';
import { drawerWidth } from './DrawerForMobile';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
			borderColor: theme.palette.grey[200],
			paddingLeft: 8,
			paddingRight: 8,
		},
	})
);

const DrawerForDesktop: React.FC = () => {
	const classes = useStyles();
	return (
		<Drawer
			variant="permanent"
			className={classes.drawer}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<PageDrawer />
			<Typography align="center">{process.env.VERSION}</Typography>
		</Drawer>
	);
};

export default DrawerForDesktop;
