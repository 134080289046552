import { createAsyncThunk } from '@reduxjs/toolkit';
import { TableStructureDetailDto } from 'src/api/api';
import { RapydlyApi } from 'src/api/RapydlyApi';
import { SchemaName } from 'src/model/schemaName';

export const fetchAllTableStructureDetail = createAsyncThunk(
	'tableStructureDetail/getAll',
	async ({ client }: { client: RapydlyApi }) => {
		const response = await client.tableStructureDetail_GetAll();
		return response;
	}
);

export const fetchTableStructureDetail = createAsyncThunk(
	'tableStructureDetail/get',
	async ({ schema, name, client }: SchemaName & { client: RapydlyApi }) => {
		const response = await client.tableStructureDetail_Get(schema, name);
		return response;
	}
);

export const updateTableStructureDetail = createAsyncThunk(
	'tableStructureDetail/update',
	async ({
		newTableStructureDetail,
		client,
	}: {
		client: RapydlyApi;
		previousTableStructureDetail: TableStructureDetailDto;
		newTableStructureDetail: TableStructureDetailDto;
	}) => {
		const wasSuccess = await client.tableStructureDetail_Update(newTableStructureDetail);
		return { wasSuccess };
	}
);
