import DescriptionIcon from '@mui/icons-material/Description';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useStyles } from './DrawerContents';

export const GoToReportsButton = () => {
	const { pathname } = useLocation();
	const classes = useStyles();
	const history = useHistory();

	return (
		<List
			className={classNames(
				classes.listItem,
				pathname === '/reports' ? classes.selected : undefined
			)}
		>
			<ListItem
				id={`listItemButton-reports`}
				button
				onClick={(): void => history.push('/reports')}
			>
				<ListItemIcon className={classes.icon}>
					<DescriptionIcon color="inherit" />
				</ListItemIcon>
				<ListItemText primary="Reports" />
			</ListItem>
		</List>
	);
};
