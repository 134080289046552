/* eslint-disable @typescript-eslint/no-explicit-any */
// import 'typeface-roboto';

import * as React from 'react';
import { RapydlyApiProvider } from 'src/api/RapydlyApiContext';
import { RapydlyApiNoTenantProvider } from 'src/api/RapydlyApiNoTenantContext';
import { RapydlyApiNoTenantOrDatabaseProvider } from 'src/api/RapydlyApiNoTenantOrDatabaseContext';
import ResponsiveDrawer from 'src/components/drawer/ResponsiveDrawer';
import { RouteModel } from 'src/model/components/routes/RouteModel';

import { AccountAlertsLoader } from './AccountAlertsLoader';
import { AppCapabilitiesLoader } from './AppCapabilitiesLoader';
import { DatabaseLoadingWrapper } from './DatabaseLoadingWrapper';
import {
	OrganisationPreferencesLoadingWrapper,
	PreferencesLoadingWrapper,
} from './PreferencesLoadingWrapper';
import Routes from './Routes';
import { TenantsLoadingWrapper } from './TenantsLoader';
import { UserAndDatabaseDefinitionLoadingWrapper } from './UserAndDatabaseDefinitionLoadingWrapper';

// Other Pages
const HomePage = React.lazy(() => import('./home'));
const CustomPage = React.lazy(() => import('./custom'));
const ReportsPage = React.lazy(() => import('./reports'));
const RowEditPage = React.lazy(() => import('./rowEdit'));

// Single Tenant Pages
const OverviewPage = React.lazy(() => import('./overview'));
const StoredProcedurePage = React.lazy(() => import('./storedProcedures'));
const FunctionPage = React.lazy(() => import('./functions'));
const ViewPage = React.lazy(() => import('./view'));
const RowNewPage = React.lazy(() => import('./rowNew'));
const NodesPage = React.lazy(() => import('./node'));

// Admin Pages
const FunctionsAdminPage = React.lazy(() => import('./functionsAdmin'));
const AdminPage = React.lazy(() => import('./admin'));
const OrganisationAdminPage = React.lazy(() => import('./adminOrganisation'));
const CustomPageAdminPage = React.lazy(() => import('./customAdmin'));
const ReportsAdminPage = React.lazy(() => import('./reportsAdmin'));
const StoredProcedureAdminPage = React.lazy(() => import('./storedProceduresAdmin'));
const TableAdminPage = React.lazy(() => import('./tableAdmin'));
const ViewAdminPage = React.lazy(() => import('./viewAdmin'));

export const getGlobalBackgroundImage = (primaryColour: string, secondaryColour: string) =>
	`url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1920' height='1080' preserveAspectRatio='none' viewBox='0 0 1920 1080'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1041%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c134 C 128%2c138.6 384%2c176.2 640%2c157 C 896%2c137.8 1024%2c35.8 1280%2c38 C 1536%2c40.2 1792%2c142 1920%2c168L1920 1080L0 1080z' fill='rgba(255%2c 255%2c 255%2c 0)'%3e%3c/path%3e%3cpath d='M 0%2c404 C 128%2c419.4 384%2c498.2 640%2c481 C 896%2c463.8 1024%2c315.2 1280%2c318 C 1536%2c320.8 1792%2c459.6 1920%2c495L1920 1080L0 1080z' fill='rgba(255%2c 255%2c 255%2c 0)'%3e%3c/path%3e%3cpath d='M 0%2c619 C 128%2c652 384%2c782.2 640%2c784 C 896%2c785.8 1024%2c636.8 1280%2c628 C 1536%2c619.2 1792%2c717.6 1920%2c740L1920 1080L0 1080z' fill='${secondaryColour}'%3e%3c/path%3e%3cpath d='M 0%2c994 C 96%2c972.8 288%2c882.4 480%2c888 C 672%2c893.6 768%2c1031.8 960%2c1022 C 1152%2c1012.2 1248%2c842 1440%2c839 C 1632%2c836 1824%2c973.4 1920%2c1007L1920 1080L0 1080z' fill='${primaryColour}'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1041'%3e%3crect width='1920' height='1080' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");`;

export const routes: Array<RouteModel> = [
	{ path: '/', component: HomePage },
	{ path: '/home', component: HomePage },
	{ path: '/reports', component: ReportsPage },
	{ path: '/reports/admin', component: ReportsAdminPage },
	{ path: '/overview', component: OverviewPage },
	{ path: '/admin', component: AdminPage },
	{ path: '/organisation-admin', component: OrganisationAdminPage },
	{ path: '/sp/:schema/:name', component: StoredProcedurePage },
	{ path: '/sp/:schema/:name/admin', component: StoredProcedureAdminPage },
	{ path: '/fn/:schema/:name', component: FunctionPage },
	{ path: '/fn/:schema/:name/admin', component: FunctionsAdminPage },
	{ path: '/views/:category/:node', component: ViewPage },
	{ path: '/views/:category/:node/admin', component: ViewAdminPage },
	{ path: '/c/:pageName', component: CustomPage },
	{ path: '/c/:pageName/admin', component: CustomPageAdminPage },
	{ path: '/:category/:node', component: NodesPage },
	{ path: '/:category/:node/admin', component: TableAdminPage },
	{ path: '/:category/:node/edit', component: RowEditPage },
	{ path: '/:category/:node/new', component: RowNewPage },
];

const App: React.FC = () => {
	return (
		<div style={{ display: 'flex' }}>
			<RapydlyApiNoTenantOrDatabaseProvider>
				<DatabaseLoadingWrapper>
					<RapydlyApiNoTenantProvider>
						<PreferencesLoadingWrapper>
							<TenantsLoadingWrapper>
								<RapydlyApiProvider>
									<OrganisationPreferencesLoadingWrapper>
										<UserAndDatabaseDefinitionLoadingWrapper>
											<AppCapabilitiesLoader />
											<AccountAlertsLoader />
											<ResponsiveDrawer />
											<Routes />
										</UserAndDatabaseDefinitionLoadingWrapper>
									</OrganisationPreferencesLoadingWrapper>
								</RapydlyApiProvider>
							</TenantsLoadingWrapper>
						</PreferencesLoadingWrapper>
					</RapydlyApiNoTenantProvider>
				</DatabaseLoadingWrapper>
			</RapydlyApiNoTenantOrDatabaseProvider>
		</div>
	);
};

export default App;
