import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface SelectInputProps {
	disabled?: boolean;
	required?: boolean;
	errored?: boolean;
	fieldName: string;
	label?: string;
	value?: string;
	values: { label: string; value: string }[];
	onChange: (newValue: string) => void;
}

const SelectInput = ({
	disabled,
	fieldName,
	label,
	required,
	errored,
	value,
	values,
	onChange,
}: SelectInputProps) => {
	return (
		<FormControl fullWidth variant="outlined" disabled={disabled}>
			{label && <InputLabel id={`label_radioButtonGroup_${fieldName}`}>{label}</InputLabel>}
			<Select
				required={required}
				error={errored}
				key={fieldName}
				labelId={`label_radioButtonGroup_${fieldName}`}
				id={`radioButtonGroup_${fieldName}`}
				value={value ?? ''}
				label={label}
				readOnly={disabled}
				onChange={(event) => onChange(event.target.value as string)}
			>
				{values.map((v) => (
					<MenuItem
						key={v.value}
						id={`radioButtonOptionFor_${fieldName}_${v}`}
						value={v.value}
					>
						{v.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectInput;
