import { useKeycloak } from '@react-keycloak/web';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { serverUrl } from 'src/clientConfig';
import { selectSelectedDatabaseId } from 'src/redux/selectors/preferencesLocal';
import { selectSelectedTenantId } from 'src/redux/selectors/tenants';
import { selectUserRunningAs } from 'src/redux/selectors/user';

import { RapydlyApi } from './RapydlyApi';

const RapydlyApiContext = React.createContext<RapydlyApi>(
	new RapydlyApi(serverUrl, '', () => Promise.resolve())
);

export const RapydlyApiProvider: React.FC = ({ children }) => {
	const runningAsAlias = useSelector(selectUserRunningAs);
	const selectedDatabaseId = useSelector(selectSelectedDatabaseId);
	const selectedTenantId = useSelector(selectSelectedTenantId);
	const { keycloak } = useKeycloak();

	const rapydlyApi = useMemo(
		() =>
			new RapydlyApi(
				serverUrl,
				selectedDatabaseId ?? '',
				async () => await keycloak.logout(),
				runningAsAlias ?? '',
				() => keycloak.token ?? '',
				selectedTenantId
			),
		[selectedDatabaseId, runningAsAlias, keycloak, selectedTenantId]
	);
	return <RapydlyApiContext.Provider value={rapydlyApi}>{children}</RapydlyApiContext.Provider>;
};

export const useRapydlyApi = () => useContext(RapydlyApiContext);
