import { createSelector } from '@reduxjs/toolkit';
import { ProgrammabilityDef } from 'src/api/api';

import { ExtendedNodeDefinition, ExtendedRelationship } from '../models/tables';
import { RootState } from '../store';
import {
	selectAppPreferences,
	selectFunctionPreferences,
	selectTablePreferences,
	selectTablePreferencesAll,
} from './preferences';

const selectDatabaseDefinitionSlice = (rootState: RootState) => rootState.databaseDefinitionSlice;

export const selectDatabaseName = createSelector(selectDatabaseDefinitionSlice, (a) =>
	a.data ? a.data.name : null
);

export const selectDatabaseDefinitionIsLoading = createSelector(
	selectDatabaseDefinitionSlice,
	(a) => a.isLoading
);

export const selectDatabaseDefinitionError = createSelector(
	selectDatabaseDefinitionSlice,
	(a) => a.error
);

export const selectViewDefinitions = createSelector(selectDatabaseDefinitionSlice, (a) =>
	a.data ? a.data.views : null
);

export const selectStoredProcedureDefinitions = createSelector(selectDatabaseDefinitionSlice, (a) =>
	a.data ? a.data.storedProcedures : null
);

export const selectFunctionDefinitions = createSelector(selectDatabaseDefinitionSlice, (a) =>
	a.data ? a.data.functions : null
);

export const selectTableDefinitions = createSelector(
	selectDatabaseDefinitionSlice,
	selectAppPreferences,
	(a, p) => {
		const tables = a.data?.tables ?? [];
		const pseudoTables = p.pseudoTables ?? [];
		return [...tables, ...pseudoTables];
	}
);

export const selectTableDefinitionsWithPreferencesApplied = createSelector(
	selectTableDefinitions,
	selectAppPreferences,
	selectTablePreferencesAll,
	(tables, prefs, tablePrefs) =>
		tables
			? tables.map((t) => ({
					...t,
					schemaDisplayName:
						prefs.schemaDisplayNames && prefs.schemaDisplayNames[t.category!],
					tableDisplayName: tablePrefs[`${t.category}.${t.name}`]?.displayName,
					columnsToShow: tablePrefs[`${t.category}.${t.name}`]?.columnsToShow,
					columnDisplayNames: tablePrefs[`${t.category}.${t.name}`]?.columnDisplayNames,
					relationshipDisplayNames:
						tablePrefs[`${t.category}.${t.name}`]?.relationshipDisplayNames,
					fileColumns: tablePrefs[`${t.category}.${t.name}`]?.fileColumns,
					dropdownLabelColumns:
						tablePrefs[`${t.category}.${t.name}`]?.dropdownLabelColumns,
					showColumnNameonDropdown:
						tablePrefs[`${t.category}.${t.name}`]?.showColumnNameonDropdown,
					isHiddenFromUI: prefs.tablesToHide?.includes(`${t.category}.${t.name}`),
			  }))
			: null
);

const selectTableDefinitionDictionary = createSelector(
	selectTableDefinitionsWithPreferencesApplied,
	(a) =>
		a
			? a.reduce((b, c) => {
					b[`${c.category!}.${c.name!}`] = c;
					return b;
			  }, {} as Record<string, ExtendedNodeDefinition>)
			: null
);

export const selectTableDefinition = (schema?: string, table?: string) =>
	createSelector(
		selectTableDefinitionDictionary,
		selectTablePreferences(schema, table),
		(tables, tablePreference) => {
			if (!tables || !tables[`${schema}.${table}`]) return null;
			const def = tables[`${schema}.${table}`];

			return {
				...def,
				fields:
					tablePreference.columnOrder && tablePreference.columnOrder.length && def.fields
						? [...(def.fields ?? [])].sort(
								(a, b) =>
									tablePreference.columnOrder!.indexOf(a.name!) -
									tablePreference.columnOrder!.indexOf(b.name!)
						  )
						: def.fields,
			} as ExtendedNodeDefinition;
		}
	);

export const selectViewDefinition = (schema?: string, view?: string) =>
	createSelector(selectViewDefinitions, (a) =>
		a ? a.find((b) => b.category === schema && b.name === view)! : null
	);

export const selectStoredProcedureDefinition =
	(schema?: string, name?: string) => (rootState: RootState) =>
		rootState?.databaseDefinitionSlice?.data?.storedProcedures?.find(
			(b) => b.schema === schema && b.name === name
		);

// export const selectFunctionDefinition =
// 	(schema?: string, name?: string) => (rootState: RootState) =>
// 		rootState?.databaseDefinitionSlice?.data?.functions?

export const selectFunctionDefinition = (schema?: string, name?: string) =>
	createSelector(
		selectFunctionDefinitions,
		selectFunctionPreferences(schema, name),
		(allFunctionDefs, prefs) => {
			if (!allFunctionDefs) return undefined;

			let thisFunctionDef: ProgrammabilityDef | undefined = allFunctionDefs.find(
				(b) => b.schema === schema && b.name === name
			);
			if (!thisFunctionDef) return undefined;
			return {
				...thisFunctionDef,
				output: prefs.columnOrder?.length
					? [...(thisFunctionDef.output ?? [])].sort(
							(a, b) =>
								prefs.columnOrder!.indexOf(a.name!) -
								prefs.columnOrder!.indexOf(b.name!)
					  )
					: thisFunctionDef.output,
			} as ProgrammabilityDef;
		}
	);

export const selectDatabaseSchemas = createSelector(selectTableDefinitions, (a) =>
	// @ts-ignore
	a ? [...new Set(a?.map((b) => b.category!))] : []
);

export const selectTableDefinitionWithForeignDefinition = (schema: string, table: string) =>
	createSelector(
		selectTableDefinitionDictionary,
		selectTableDefinition(schema, table),
		(allDefs, thisTableDef) => {
			if (thisTableDef && allDefs) {
				thisTableDef.foreignKeysWithDefinitions = thisTableDef?.foreignKeys?.map((fk) => {
					const extendedFK = {
						...fk,
						foreignTableDefinition:
							allDefs[
								fk.schema === schema && fk.table === table
									? `${fk.foreignSchema}.${fk.foreignTable}`
									: `${fk.schema}.${fk.table}`
							],
					} as ExtendedRelationship;

					extendedFK.friendlyName =
						thisTableDef.relationshipDisplayNames &&
						thisTableDef.relationshipDisplayNames[fk.name!];
					extendedFK.isOneToManyChild =
						(fk.foreignSchema === schema && fk.foreignTable === table) ||
						(fk.foreignSchema === undefined &&
							fk.foreignTable === undefined &&
							schema === 'pseudo');
					return extendedFK;
				});
				return thisTableDef;
			}
			return null;
		}
	);
