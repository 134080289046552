import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

export const fetchDatabaseDefinition = createAsyncThunk(
	'databaseDefinition/get',
	async (client: RapydlyApi) => {
		const response = await client.tables_GetDatabaseDefinition();
		return response;
	}
);

export const fetchDatabaseDefinitionAndRefreshCache = createAsyncThunk(
	'databaseDefinitionAndRefreshCache/get',
	async (client: RapydlyApi) => {
		const response = await client.tables_GetDatabaseDefinitionRefreshCache();
		return response;
	}
);
