import { TextField } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

interface Props {
	label: string;
	value: string;
	onChange: (event: string) => void;
	type?: string;
	readonly?: boolean;
	required?: boolean;
	fieldName: string;
	onBlur?: () => void;
}

const DatePickerWrapper: React.FC<Props> = ({
	label,
	value,
	onChange,
	type,
	readonly,
	required,
	fieldName,
	onBlur,
}) => {
	const isValid = !required || !!value;

	const formattedValue = value
		? format(new Date(value), 'yyyy-MM-dd hh:mm:ss').replace(' ', 'T')
		: '';

	return (
		<TextField
			required={required}
			error={!isValid}
			aria-readonly={readonly}
			disabled={readonly}
			id={`datetime-input-${fieldName}`}
			label={label}
			type={type === 'time' ? 'time' : 'datetime-local'}
			value={formattedValue}
			fullWidth
			variant="outlined"
			InputLabelProps={{ shrink: true }}
			onChange={(event) => {
				onChange(event.target.value.replace('T', ' '));
			}}
			onBlur={() => onBlur && onBlur()}
		/>
	);
};

export default DatePickerWrapper;
