import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { Functions } from '../models/functions';
import { runFunction } from '../thunks/functions';

const initialState: ISlice<Functions> = {
	isLoading: false,
	data: {},
};

const slice = createSlice({
	name: 'functions',
	initialState,
	reducers: {
		updateFunctionInput(
			state,
			action: PayloadAction<{
				schema: string;
				functionName: string;
				inputName: string;
				value: string;
			}>
		) {
			if (!state.data[`${action.payload.schema}.${action.payload.functionName}`]) {
				state.data[`${action.payload.schema}.${action.payload.functionName}`] = {
					didError: false,
					output: null,
					input: {
						[action.payload.inputName]: action.payload.value,
					},
				};
			} else if (
				!state.data[`${action.payload.schema}.${action.payload.functionName}`].input
			) {
				state.data[`${action.payload.schema}.${action.payload.functionName}`].input = {
					[action.payload.inputName]: action.payload.value,
				};
			} else {
				state.data[`${action.payload.schema}.${action.payload.functionName}`].input = {
					...state.data[`${action.payload.schema}.${action.payload.functionName}`].input,
					[action.payload.inputName]: action.payload.value,
				};
			}
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(runFunction.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(runFunction.rejected, (state, action) => {
				state.isLoading = false;

				if (
					!state.data[`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`]
				) {
					state.data[
						`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
					] = {
						didError: false,
						output: null,
						input: {},
					};
				}

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].output = null;

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].didError = true;
			})
			.addCase(runFunction.fulfilled, (state, action) => {
				state.isLoading = false;

				if (
					!state.data[`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`]
				) {
					state.data[
						`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
					] = {
						didError: false,
						output: null,
						input: {},
					};
				}

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].output = action.payload;

				state.data[
					`${action.meta.arg.request.schema}.${action.meta.arg.request.name}`
				].didError = false;
			}),
});

export const { updateFunctionInput } = slice.actions;

export default slice.reducer;
