import { createSlice } from '@reduxjs/toolkit';
import { AuthOverview } from 'src/api/api';

import { ISlice } from '../models';
import { fetchUserOverview } from '../thunks/user';

interface ViewUserAs {
	viewAs?: string;
	nameOnly: string;
}

const initialState: ISlice<AuthOverview & ViewUserAs> = {
	isLoading: true,
	data: {
		name: 'Anonymous',
		groups: [],
		isAuthenticated: false,
		nameOnly: 'Anonymous',
	},
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchUserOverview.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchUserOverview.rejected, (state, action) => {
				state = { ...initialState };
				if (action.error) {
					state.error = 'An error occured getting user overview';
				}
			})
			.addCase(fetchUserOverview.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = {
					...state.data,
					...action.payload,
					name: action.payload.username,
					nameOnly: action.payload.name || action.payload.username!,
				};
			}),
});

export default slice.reducer;
