import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import {
	AppPreferences,
	PreferencesSlice,
	TablePreferences,
	TenantPreferences,
	UserPreferences,
} from '../models/preference';
import { selectDatabase } from '../thunks/databases';
import {
	fetchGraphData,
	fetchGraphDataWithColumns,
	fetchMultipleGraphData,
	fetchPreferences,
	fetchTenantPreferences,
	fetchUpdateAppPreferences,
	fetchUpdateTablePreferences,
	fetchUpdateTenantPreferences,
	fetchUpdateUserPreferences,
} from '../thunks/preferences';

const initialState: ISlice<PreferencesSlice> = {
	isLoading: true,
	data: {
		appPreferences: {
			authorizationMappings: {},
		},
		tablePreferences: {},
		userPreferences: {
			dashboardCards: [],
		},
		tenantPreferences: {},
		userPreferencesCache: {
			dashboardCardGraphData: {},
		},
	},
};

const slice = createSlice({
	name: 'preferences',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchTenantPreferences.pending, (state) => {
				state.error = undefined;
				state.isLoading = true;
			})
			.addCase(fetchTenantPreferences.rejected, (state, action) => {
				if (action.error) {
					state.error = 'There was an error getting organisation preferences.';
				}
				state.isLoading = false;
			})
			.addCase(fetchTenantPreferences.fulfilled, (state, action) => {
				state.isLoading = false;

				state.data.tenantPreferences = (
					'tenant' in action.payload ? JSON.parse(action.payload['tenant']) : {}
				) as TenantPreferences;
			})

			.addCase(fetchPreferences.pending, (state) => {
				state.error = undefined;
				state.isLoading = true;
			})
			.addCase(fetchPreferences.rejected, (state, action) => {
				if (action.error) {
					state.error = 'There was an error getting preferences.';
				}
				state.isLoading = false;
			})
			.addCase(fetchPreferences.fulfilled, (state, action) => {
				state.isLoading = false;

				state.data.userPreferences = (
					'user' in action.payload ? JSON.parse(action.payload['user']) : {}
				) as UserPreferences;

				state.data.appPreferences = (
					'app' in action.payload ? JSON.parse(action.payload['app']) : {}
				) as AppPreferences;

				if (state.data.appPreferences && !state.data.appPreferences.authorizationMappings) {
					state.data.appPreferences.authorizationMappings = {};
				}

				state.data.tablePreferences = Object.keys(action.payload)
					.filter((a) => a !== 'app' && a !== 'user' && a !== 'tenant')
					.reduce((all, a) => {
						all[a] = JSON.parse(action.payload[a]);
						return all;
					}, {} as Record<string, TablePreferences>);
			})

			.addCase(fetchUpdateAppPreferences.pending, (state, action) => {
				state.isLoading = true;
				state.data.appPreferences = action.meta.arg.newPreferences;
			})
			.addCase(fetchUpdateAppPreferences.rejected, (state, action) => {
				state.isLoading = false;
				state.data.appPreferences = action.meta.arg.oldPreferences;
			})
			.addCase(fetchUpdateAppPreferences.fulfilled, (state) => {
				state.isLoading = false;
			})

			.addCase(fetchUpdateUserPreferences.pending, (state, action) => {
				state.isLoading = true;
				state.data.userPreferences = action.meta.arg.newPreferences;
			})
			.addCase(fetchUpdateUserPreferences.rejected, (state, action) => {
				state.isLoading = false;
				state.data.userPreferences = action.meta.arg.oldPreferences;
			})
			.addCase(fetchUpdateUserPreferences.fulfilled, (state) => {
				state.isLoading = false;
			})

			.addCase(fetchUpdateTenantPreferences.pending, (state, action) => {
				state.isLoading = true;
				state.data.tenantPreferences = action.meta.arg.newPreferences;
			})
			.addCase(fetchUpdateTenantPreferences.rejected, (state, action) => {
				state.isLoading = false;
				state.data.tenantPreferences = action.meta.arg.oldPreferences;
			})
			.addCase(fetchUpdateTenantPreferences.fulfilled, (state) => {
				state.isLoading = false;
			})

			.addCase(fetchUpdateTablePreferences.pending, (state, action) => {
				state.isLoading = true;
				state.data.tablePreferences[`${action.meta.arg.schema}.${action.meta.arg.table}`] =
					action.meta.arg.newPreferences;
			})
			.addCase(fetchUpdateTablePreferences.rejected, (state, action) => {
				state.isLoading = false;
				state.data.tablePreferences[`${action.meta.arg.schema}.${action.meta.arg.table}`] =
					action.meta.arg.oldPreferences;
			})
			.addCase(fetchUpdateTablePreferences.fulfilled, (state) => {
				state.isLoading = false;
			})

			.addCase(fetchGraphData.pending, (state, action) => {
				if (
					!state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.request.id}`
					]
				) {
					state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.request.id}`
					] = { isLoading: true, data: [], seriesData: [] };
				} else {
					state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.request.id}`
					].isLoading = true;
				}
			})
			.addCase(fetchGraphData.rejected, (state, action) => {
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.request.id}`
				].isLoading = false;
			})
			.addCase(fetchGraphData.fulfilled, (state, action) => {
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.request.id}`
				].isLoading = false;
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.request.id}`
				].data =
					(action.payload &&
						action.payload[0].data?.sort((a, b) => (a.x > b.x ? 1 : -1))) ??
					[];
			})

			.addCase(fetchGraphDataWithColumns.pending, (state, action) => {
				if (
					!state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.request.id}`
					]
				) {
					state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.request.id}`
					] = { isLoading: true, data: [], seriesData: [] };
				} else {
					state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.request.id}`
					].isLoading = true;
				}
			})
			.addCase(fetchGraphDataWithColumns.rejected, (state, action) => {
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.request.id}`
				].isLoading = false;
			})
			.addCase(fetchGraphDataWithColumns.fulfilled, (state, action) => {
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.request.id}`
				].isLoading = false;
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.request.id}`
				].data = action.payload ?? [];
			})

			.addCase(fetchMultipleGraphData.pending, (state, action) => {
				if (
					!state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.cardId}`
					]
				) {
					state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.cardId}`
					] = { isLoading: true, data: [], seriesData: [] };
				} else {
					state.data.userPreferencesCache.dashboardCardGraphData[
						`${action.meta.arg.cardId}`
					].isLoading = true;
				}
			})
			.addCase(fetchMultipleGraphData.rejected, (state, action) => {
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.cardId}`
				].isLoading = false;
			})
			.addCase(fetchMultipleGraphData.fulfilled, (state, action) => {
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.cardId}`
				].isLoading = false;
				state.data.userPreferencesCache.dashboardCardGraphData[
					`${action.meta.arg.cardId}`
				].seriesData =
					action.payload?.map(
						(d) => d.data?.sort((a, b) => (a.x > b.x ? 1 : -1)) ?? []
					) ?? [];
			})
			.addCase(selectDatabase.fulfilled, (state) => {
				state.isLoading = initialState.isLoading;
				state.error = initialState.error;
				state.data = initialState.data;
			}),
});

export default slice.reducer;
