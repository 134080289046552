enum SqlTypes {
	int = 'int',
	decimal = 'decimal',
	numeric = 'numeric',
	float = 'float',
	real = 'real',
	year = 'year',
	bit = 'bit',
	binery = 'binery',
	date = 'date',
	datetime = 'datetime',
	timestamp = 'timestamp',
	time = 'time',
	string = 'string',
}

export const SqlTypesArray: string[] = Object.values(SqlTypes);

export const sqlTypesToColumnDefs = (sqlType: string) => {
	if (!sqlType) return 'string';

	if (
		['int', 'decimal', 'numeric', 'float', 'real', 'year'].some((a) =>
			sqlType.toLowerCase().includes(a)
		)
	) {
		return 'numeric';
	}
	if (['decimal'].some((a) => sqlType.toLowerCase().includes(a))) {
		return 'currency';
	}

	if (['bit', 'binery', 'boolean'].some((a) => sqlType.toLowerCase().includes(a))) {
		return 'boolean';
	}

	if (
		['datetime', 'timestamp', 'timestamp with time zone'].some((a) =>
			sqlType.toLowerCase().includes(a)
		)
	) {
		return 'datetime';
	}
	if (['date'].some((a) => sqlType.toLowerCase().includes(a))) {
		return 'date';
	}

	if (['time'].some((a) => sqlType.toLowerCase().includes(a))) {
		return 'time';
	}

	return 'string';
};
