import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import accountAlertsSlice from './reducers/accountAlerts';
import appStateSlice from './reducers/appState';
import capabilitiesSlice from './reducers/capabilities';
import currentRowSlice from './reducers/currentRow';
import databaseDefinitionSlice from './reducers/databaseDefinition';
import databaseRelationshipOverviewSlice from './reducers/databaseRelationshipOverview';
import databasesSlice from './reducers/databases';
import dropdownValuesSlice from './reducers/dropdownValues';
import functionsSlice from './reducers/functions';
import preferencesSlice from './reducers/preferences';
import preferencesLocalSlice from './reducers/preferencesLocal';
import pseudoFunctionsSlice from './reducers/pseudoFunctions';
import snackbarSlice from './reducers/snackbar';
import storedProceduresSlice from './reducers/storedProcedures';
import tablesSlice from './reducers/tables';
import tableStructureDetailSlice from './reducers/tableStructureDetail';
import tenantsSlice from './reducers/tenants';
import userSlice from './reducers/user';
import viewsSlice from './reducers/views';

const reducers = combineReducers({
	appStateSlice,
	databaseDefinitionSlice,
	userSlice,
	databasesSlice,
	preferencesLocalSlice,
	snackbarSlice,
	tablesSlice,
	currentRowSlice,
	dropdownValuesSlice,
	preferencesSlice,
	viewsSlice,
	capabilitiesSlice,
	tenantsSlice,
	databaseRelationshipOverviewSlice,
	storedProceduresSlice,
	functionsSlice,
	accountAlertsSlice,
	tableStructureDetailSlice,
	pseudoFunctionsSlice,
});

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['preferencesLocalSlice', 'databaseDefinitionSlice', 'tenantsSlice'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
