import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetValuesForGraphRequest, RunFunctionRequest } from 'src/api/api';
import { RapydlyApi } from 'src/api/RapydlyApi';

import {
	AppPreferences,
	TablePreferences,
	TenantPreferences,
	UserPreferences,
} from '../models/preference';

export const fetchPreferences = createAsyncThunk('preferences/get', async (client: RapydlyApi) => {
	const response = await client.preferences_Get();
	return response;
});

export const fetchTenantPreferences = createAsyncThunk(
	'preferences/getTenant',
	async (client: RapydlyApi) => {
		const response = await client.preferences_GetTenant();
		return response;
	}
);

interface UpdateTablePreferencesRequest {
	schema: string;
	table: string;
	newPreferences: TablePreferences;
	oldPreferences: TablePreferences;
}

interface UpdateAppPreferencesRequest {
	newPreferences: AppPreferences;
	oldPreferences: AppPreferences;
}

interface UpdateUserPreferencesRequest {
	newPreferences: UserPreferences;
	oldPreferences: UserPreferences;
}

interface UpdateTenantPreferencesRequest {
	newPreferences: TenantPreferences;
	oldPreferences: TenantPreferences;
}

interface ClientRequest {
	client: RapydlyApi;
}

interface FetchGraphDataRequest extends GetValuesForGraphRequest {
	id?: number | 'new';
}

export const fetchUpdateTablePreferences = createAsyncThunk(
	'preferences/updateTable',
	// @ts-ignore
	async ({
		table,
		schema,
		newPreferences,
		client,
	}: UpdateTablePreferencesRequest & ClientRequest) => {
		const response = await client.preferences_Update({
			section: `${schema}.${table}`,
			preferencesJson: JSON.stringify(newPreferences),
		});
		return response;
	}
);

export const fetchUpdateAppPreferences = createAsyncThunk(
	'preferences/updateApp',
	// @ts-ignore
	async ({ newPreferences, client }: UpdateAppPreferencesRequest & ClientRequest) => {
		const response = await client.preferences_Update({
			section: 'app',
			preferencesJson: JSON.stringify(newPreferences),
		});
		return response;
	}
);

export const fetchUpdateUserPreferences = createAsyncThunk(
	'preferences/updateUser',
	// @ts-ignore
	async ({ newPreferences, client }: UpdateUserPreferencesRequest & ClientRequest) => {
		const response = await client.preferences_UpdateForUser({
			preferencesJson: JSON.stringify(newPreferences),
		});
		return response;
	}
);

export const fetchUpdateTenantPreferences = createAsyncThunk(
	'preferences/updateTenant',
	// @ts-ignore
	async ({ newPreferences, client }: UpdateTenantPreferencesRequest & ClientRequest) => {
		const response = await client.preferences_UpdateForTenant({
			preferencesJson: JSON.stringify(newPreferences),
		});
		return response;
	}
);

export const fetchGraphData = createAsyncThunk(
	'preferences/fetchGraphData',
	async ({ request, client }: { request: FetchGraphDataRequest; client: RapydlyApi }) => {
		const response = await client.functions_RunForGraph([request]);
		return response;
	}
);

export const fetchGraphDataWithColumns = createAsyncThunk(
	'preferences/fetchGraphDataWithColumns',
	async ({
		request,
		client,
	}: {
		request: RunFunctionRequest & { id: number };
		client: RapydlyApi;
	}) => {
		const response = await client.functions_Run(request);
		return response;
	}
);

export const fetchMultipleGraphData = createAsyncThunk(
	'preferences/fetchMultipleGraphData',
	async ({
		request,
		client,
	}: { request: FetchGraphDataRequest[]; cardId: string | number } & ClientRequest) => {
		const response = await client.functions_RunForGraph(request);
		return response;
	}
);
