import { Typography } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedDatabase } from 'src/redux/selectors/preferencesLocal';

export const DatabaseName = () => {
	const database = useSelector(selectSelectedDatabase);

	return (
		<div style={{ marginBottom: 8 }}>
			<Typography variant="h6" color="textPrimary">
				{database?.name}
			</Typography>
		</div>
	);
};
