import { TextField } from '@mui/material';

interface NumberInputProps {
	required?: boolean;
	errored?: boolean;
	disabled?: boolean;
	fieldName: string;
	label?: string;
	value: number | null;
	onChange: (newValue: number | null) => void;
	onBlur?: () => void;
}

const NumberInput = ({
	required,
	errored,
	disabled,
	fieldName,
	label,
	value,
	onChange,
	onBlur,
}: NumberInputProps) => {
	return (
		<TextField
			required={required}
			error={errored}
			helperText={errored}
			aria-readonly={disabled}
			disabled={disabled}
			id={`numericalTextfield-input-${fieldName}`}
			label={label}
			placeholder={label}
			fullWidth
			variant="outlined"
			type="number"
			InputLabelProps={{ shrink: true }}
			value={value}
			onChange={(event) =>
				onChange &&
				onChange(
					event.target.value === null || event.target.value === undefined
						? null
						: parseFloat(event.target.value)
				)
			}
			onBlur={() => onBlur && onBlur()}
		/>
	);
};

export default NumberInput;
