import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import { Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';
import { drawerWidth } from 'src/components/drawer/DrawerForMobile';
import ImLost from 'src/components/pages/ImLost';
import { ServerLogChildErrors } from 'src/components/wrappers/ServerLogChildErrors';

import { getGlobalBackgroundImage, routes } from './App';
import { TopAppBar } from './TopAppBar';

interface PrivateRouteParams extends RouteProps {
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteParams) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return (
					<ServerLogChildErrors>
						<Component {...props} />
					</ServerLogChildErrors>
				);
			}}
		/>
	);
};

const Routes = () => {
	const classes = useStyles();

	return (
		<div className={classes.content} id="page-content">
			<TopAppBar />
			<Switch>
				{routes.map((p) => (
					<PrivateRoute exact={true} key={p.path} path={p.path} component={p.component} />
				))}
				<Route component={ImLost} />
			</Switch>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			paddingTop: 80,
			flex: 1,
			maxWidth: `calc(100% - ${drawerWidth}px)`,
			[theme.breakpoints.down('lg')]: {
				maxWidth: '100%',
			},
		},
		// Need this here, and in App.tsx so it picks up clients theming
		'@global': {
			body: {
				backgroundColor: theme.palette.background.default,
				[theme.breakpoints.up('md')]: {
					backgroundImage: getGlobalBackgroundImage(
						theme.palette.primary.light,
						theme.palette.secondary.light
					),
					backgroundAttachment: 'fixed',
					backgroundSize: 'cover',
				},
			},
		},
	})
);

export default Routes;
