import { CssBaseline, responsiveFontSizes } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectAppColourScheme } from 'src/redux/selectors/preferences';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

export const DefaultThemeProvider: React.FC = ({ children }) => {
	const theme = responsiveFontSizes(
		createTheme({
			palette: {
				primary: {
					main: '#42903C',
				},
				secondary: {
					main: '#383D3B',
				},
				background: {
					default: grey[50],
					paper: '#ffffff',
				},
			},
			components: {
				MuiTableBody: {
					defaultProps: {
						style: {
							fontSize: 14,
						},
					},
				},
			},
		})
	);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export const TenantThemeProvider: React.FC = ({ children }) => {
	const { primaryColor, secondaryColor } = useSelector(selectAppColourScheme);

	const theme = responsiveFontSizes(
		createTheme({
			palette: {
				primary: {
					main: primaryColor?.trim() || '#42903C',
				},
				secondary: {
					main: secondaryColor?.trim() || '#383D3B',
				},
				background: {
					default: grey[50],
					paper: '#ffffff',
				},
			},
			components: {
				MuiTableBody: {
					defaultProps: {
						style: {
							fontSize: 14,
						},
					},
				},
			},
		})
	);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};
