import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { PseudoFunctionsState } from '../models/pseudoFunctions';
import {
	deletePseudoFunctionBuilderOptions,
	fetchPseudoFunctionBuilderOptions,
	fetchPseudoFunctionBuilderSql,
	updatePseudoFunctionBuilderOptions,
} from '../thunks/pseudoFunctions';

const initialState: ISlice<PseudoFunctionsState> = {
	isLoading: false,
	data: {
		allPseudoFunctionBuilderOptions: [],
	},
};

const slice = createSlice({
	name: 'pseudoFunctions',
	initialState,
	reducers: {
		// updateName(state, action: PayloadAction<string>) {
		// 	state.firstName = action.payload;
		// },
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchPseudoFunctionBuilderOptions.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchPseudoFunctionBuilderOptions.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
				state.data.allPseudoFunctionBuilderOptions = [];
			})
			.addCase(fetchPseudoFunctionBuilderOptions.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data.allPseudoFunctionBuilderOptions = action.payload;
			})

			.addCase(fetchPseudoFunctionBuilderSql.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchPseudoFunctionBuilderSql.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
				state.data.allPseudoFunctionBuilderOptions = [];
			})
			.addCase(fetchPseudoFunctionBuilderSql.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data.latestGeneratedSql = action.payload.sql;
			})

			.addCase(updatePseudoFunctionBuilderOptions.pending, (state, action) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(updatePseudoFunctionBuilderOptions.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(updatePseudoFunctionBuilderOptions.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!state.data.allPseudoFunctionBuilderOptions) {
					state.data.allPseudoFunctionBuilderOptions = [];
				}
				if (!action.meta.arg.newFunctionOption.pseudoFunctionBuilderOptions?.id) {
					state.data.allPseudoFunctionBuilderOptions.push({
						...action.meta.arg.newFunctionOption.pseudoFunctionBuilderOptions,
						id: action.payload.newOrUpdatedId,
					});
				} else {
					var index = state.data.allPseudoFunctionBuilderOptions.findIndex(
						(a) =>
							a.id ===
							action.meta.arg.newFunctionOption.pseudoFunctionBuilderOptions?.id
					);
					state.data.allPseudoFunctionBuilderOptions.splice(
						index,
						1,
						action.meta.arg.newFunctionOption.pseudoFunctionBuilderOptions
					);
				}
			})

			.addCase(deletePseudoFunctionBuilderOptions.pending, (state, action) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(deletePseudoFunctionBuilderOptions.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(deletePseudoFunctionBuilderOptions.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!state.data.allPseudoFunctionBuilderOptions) {
					state.data.allPseudoFunctionBuilderOptions = [];
				}

				var index = state.data.allPseudoFunctionBuilderOptions.findIndex(
					(a) => a.id === action.meta.arg.id
				);
				state.data.allPseudoFunctionBuilderOptions.splice(index, 1);
			}),
});

// export const {} = slice.actions;

export default slice.reducer;
