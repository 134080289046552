import { useKeycloak } from '@react-keycloak/web';
import React, { useContext, useMemo } from 'react';
import { serverUrl } from 'src/clientConfig';

import { RapydlyApi } from './RapydlyApi';

const RapydlyApiNoTenantOrDatabaseContext = React.createContext<RapydlyApi>(
	new RapydlyApi(serverUrl, '', () => Promise.resolve())
);

export const RapydlyApiNoTenantOrDatabaseProvider: React.FC = ({ children }) => {
	const { keycloak } = useKeycloak();

	const rapydlyApi = useMemo(
		() =>
			new RapydlyApi(
				serverUrl,
				'',
				async () => await keycloak.logout(),
				'',
				() => keycloak.token ?? '',
				undefined
			),
		[keycloak]
	);

	return (
		<RapydlyApiNoTenantOrDatabaseContext.Provider value={rapydlyApi}>
			{children}
		</RapydlyApiNoTenantOrDatabaseContext.Provider>
	);
};

export const useRapydlyApiNoTenantOrDatabase = () =>
	useContext(RapydlyApiNoTenantOrDatabaseContext);
