import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { selectAppPreferences } from './preferences';

const selectTenantSlice = (rootState: RootState) => rootState.tenantsSlice;

export const selectTenants = createSelector(selectTenantSlice, (a) => a.data.rows);
export const selectSelectedTenant = createSelector(selectTenantSlice, (a) => a.data.selectedTenant);
export const selectSelectedTenantId = createSelector(
	selectTenantSlice,
	selectAppPreferences,
	(a, prefs) =>
		a.data.selectedTenant && prefs?.multiTenancyIdField
			? (a.data.selectedTenant[prefs?.multiTenancyIdField] as number)
			: undefined
);
export const selectTenantsIsLoading = createSelector(selectTenantSlice, (a) => a.isLoading);
export const selectTenantsError = createSelector(selectTenantSlice, (a) => a.error);
