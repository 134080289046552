import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

import { AccountAlert } from '../models/accountAlerts';

interface SchemaName {
	schema: string;
	name: string;
	titleField: string;
	descriptionField: string;
	typeField: string;
	actionText: string;
	actionUrl: string;
}

export const fetchAccountAlerts = createAsyncThunk(
	'accountAlerts/fetch',
	async ({
		schema,
		name,
		client,
		titleField,
		descriptionField,
		typeField,
		actionText,
		actionUrl,
	}: SchemaName & { client: RapydlyApi }) => {
		const response = await client.functions_Run({
			schema: schema,
			name: name,
			inputs: {},
		});

		if (!response || !Array.isArray(response) || !response.length) {
			return [];
		} else {
			return response.map(
				(r) =>
					({
						title: r[titleField] as string,
						description: r[descriptionField] as string,
						type: r[typeField] as string,
						actionText: r[actionText] as string,
						actionUrl: r[actionUrl] as string,
					} as AccountAlert)
			);
		}
	}
);
