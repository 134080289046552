import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	AddColumnToTableRequest,
	GetTableRequest,
	UpdateColumnOnTableRequest,
	UpdateTableRowRequest,
} from 'src/api/api';
import { RapydlyApi } from 'src/api/RapydlyApi';

interface SchemaTable {
	schema: string;
	table: string;
}

interface WithClient {
	client: RapydlyApi;
}

interface RowDef extends SchemaTable {
	primaryIds: Record<string, any>;
	row: Record<string, any>;
	index: number;
}

export const fetchTable = createAsyncThunk(
	'table/fetch',
	async ({ request, client }: { request: GetTableRequest } & WithClient) => {
		const response = await client.tables_Get(request);
		return response;
	}
);

export const deleteRow = createAsyncThunk(
	'row/delete',
	// @ts-ignore
	async ({ schema, table, primaryIds, client }: RowDef & WithClient) => {
		const response = await client.tables_DeleteRow({
			schema: schema,
			table: table,
			primaryIds,
		});
		return response;
	}
);

export const updateRow = createAsyncThunk(
	'row/update',
	// @ts-ignore
	async ({
		schema,
		table,
		primaryIds,
		valuesToUpdate,
		client,
	}: UpdateTableRowRequest & WithClient) => {
		const response = await client.tables_UpdateRow({
			schema: schema,
			table: table,
			primaryIds,
			valuesToUpdate,
		});
		return response;
	}
);

export const addColumnToRow = createAsyncThunk(
	'table/addColumn',
	// @ts-ignore
	async ({ request, client }: { request: AddColumnToTableRequest; client: RapydlyApi }) => {
		const response = await client.tables_AddColumn(request);
		return response;
	}
);

export const updateColumnOnRow = createAsyncThunk(
	'table/updateColumn',
	// @ts-ignore
	async ({ request, client }: { request: UpdateColumnOnTableRequest; client: RapydlyApi }) => {
		const response = await client.tables_UpdateColumn(request);
		return response;
	}
);

export const fetchDatabaseRelationshipOverview = createAsyncThunk(
	'database/relationshipOverview',
	async ({ client }: { client: RapydlyApi }) => {
		const response = await client.tables_GetDatabaseRelationshipOverview();
		return response;
	}
);
