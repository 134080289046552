import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { Snackbar } from '../models/snackbar';
import { fetchRow } from '../thunks/currentRow';
import { fetchDropdownValues } from '../thunks/dropdownValues';
import { runFunction } from '../thunks/functions';
import {
	fetchGraphData,
	fetchMultipleGraphData,
	fetchUpdateAppPreferences,
	fetchUpdateTablePreferences,
	fetchUpdateTenantPreferences,
} from '../thunks/preferences';
import { runStoredProcedure } from '../thunks/storedProcedures';
import {
	deleteRow,
	fetchDatabaseRelationshipOverview,
	fetchTable,
	updateRow,
} from '../thunks/table';
import {
	fetchTableStructureDetail,
	updateTableStructureDetail,
} from '../thunks/tableStructureDetail';
import { fetchUserOverview } from '../thunks/user';

const initialState: ISlice<Snackbar | null> = {
	isLoading: false,
	data: null,
};

const slice = createSlice({
	name: 'snackbar',
	initialState,
	reducers: {
		showSnackbar(state, action: PayloadAction<Snackbar>) {
			state.data = action.payload;
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(updateRow.fulfilled, (state) => {
				state.isLoading = false;
				state.data = { message: 'Saved your updates.', type: 'success' };
			})
			.addCase(updateRow.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to save your updates.', type: 'error' };
			})
			// .addCase(runFunction.fulfilled, (state) => {
			// 	state.isLoading = false;
			// 	state.data = { message: 'Success.', type: 'success' };
			// })
			.addCase(runFunction.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: "Oops, that didn't work.", type: 'error' };
			})
			.addCase(runStoredProcedure.fulfilled, (state) => {
				state.isLoading = false;
				state.data = { message: 'Success.', type: 'success' };
			})
			.addCase(runStoredProcedure.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: "Oops, that didn't work.", type: 'error' };
			})
			.addCase(fetchUpdateAppPreferences.fulfilled, (state) => {
				state.isLoading = false;
				state.data = { message: 'Saved preferences.', type: 'success' };
			})
			.addCase(fetchUpdateAppPreferences.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to save preferences.', type: 'error' };
			})
			.addCase(fetchUpdateTenantPreferences.fulfilled, (state) => {
				state.isLoading = false;
				state.data = { message: 'Saved preferences.', type: 'success' };
			})
			.addCase(fetchUpdateTenantPreferences.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to save preferences.', type: 'error' };
			})
			.addCase(fetchUpdateTablePreferences.fulfilled, (state) => {
				state.isLoading = false;
				state.data = { message: 'Saved table preferences.', type: 'success' };
			})
			.addCase(fetchUpdateTablePreferences.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to save table preferences.', type: 'error' };
			})
			.addCase(fetchUserOverview.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get user info.', type: 'error' };
			})
			.addCase(fetchDropdownValues.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get data.', type: 'error' };
			})
			.addCase(fetchGraphData.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get data.', type: 'error' };
			})
			.addCase(fetchMultipleGraphData.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get data.', type: 'error' };
			})
			.addCase(fetchTable.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get data.', type: 'error' };
			})
			.addCase(deleteRow.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to remove data.', type: 'error' };
			})
			.addCase(fetchDatabaseRelationshipOverview.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get data.', type: 'error' };
			})
			.addCase(fetchRow.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get data.', type: 'error' };
			})

			.addCase(fetchTableStructureDetail.fulfilled, (state) => {
				state.isLoading = false;
				state.data = { message: 'Got table structure detail.', type: 'success' };
			})
			.addCase(fetchTableStructureDetail.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to get table structure detail.', type: 'error' };
			})

			.addCase(updateTableStructureDetail.fulfilled, (state) => {
				state.isLoading = false;
				state.data = { message: 'Updated table structure detail.', type: 'success' };
			})
			.addCase(updateTableStructureDetail.rejected, (state) => {
				state.isLoading = false;
				state.data = { message: 'Failed to update table structure detail.', type: 'error' };
			}),
});

/*


				} catch (e) {
					dispatch(showSnackbar({ message: 'Failed to get data.', type: 'error' }));
					return;
				}

*/

export const { showSnackbar } = slice.actions;

export default slice.reducer;
