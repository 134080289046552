import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { TableStructureDetails } from '../models/tableStructureDetail';
import {
	fetchAllTableStructureDetail,
	fetchTableStructureDetail,
	updateTableStructureDetail,
} from '../thunks/tableStructureDetail';

const initialState: ISlice<TableStructureDetails> = {
	isLoading: false,
	data: {},
};

const slice = createSlice({
	name: 'tableStructureDetail',
	initialState,
	reducers: {
		// updateName(state, action: PayloadAction<string>) {
		// 	state.firstName = action.payload;
		// },
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchTableStructureDetail.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchTableStructureDetail.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
				state.data[`${action.meta.arg.schema}.${action.meta.arg.name}`] = {};
			})
			.addCase(fetchTableStructureDetail.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data[`${action.meta.arg.schema}.${action.meta.arg.name}`] = action.payload;
			})

			.addCase(fetchAllTableStructureDetail.pending, (state) => {
				state.isLoading = true;
				state.error = undefined;
			})
			.addCase(fetchAllTableStructureDetail.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(fetchAllTableStructureDetail.fulfilled, (state, action) => {
				state.isLoading = false;
				action.payload.forEach((p) => {
					state.data[`${p.schema}.${p.table}`] = p;
				});
			})

			.addCase(updateTableStructureDetail.pending, (state, action) => {
				state.isLoading = true;
				state.error = undefined;
				state.data[
					`${action.meta.arg.newTableStructureDetail.schema}.${action.meta.arg.newTableStructureDetail.table}`
				] = action.meta.arg.newTableStructureDetail;
			})
			.addCase(updateTableStructureDetail.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
				state.data[
					`${action.meta.arg.previousTableStructureDetail.schema}.${action.meta.arg.previousTableStructureDetail.table}`
				] = action.meta.arg.previousTableStructureDetail;
			})
			.addCase(updateTableStructureDetail.fulfilled, (state, action) => {
				state.isLoading = false;
			}),
});

// export const {} = slice.actions;

export default slice.reducer;
