import { Checkbox, FormControlLabel } from '@mui/material';

interface CheckBoxInputProps {
	disabled?: boolean;
	required?: boolean;
	errored?: boolean;
	fieldName: string;
	label?: string;
	value?: boolean;
	onChange: (newValue: boolean) => void;
}

const CheckBoxInput = ({
	disabled,
	fieldName,
	label,
	required,
	errored,
	value,
	onChange,
}: CheckBoxInputProps) => {
	return (
		<FormControlLabel
			id={`label_checkBox_${fieldName}`}
			control={
				<Checkbox
					disabled={disabled}
					readOnly={disabled}
					required={required}
					id={`checkBox_${fieldName}`}
					checked={value}
					onChange={(a) => onChange(a.target.checked)}
					value={!!value}
					color="primary"
				/>
			}
			label={label}
		/>
	);
};

export default CheckBoxInput;
