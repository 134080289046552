import { Grid } from '@mui/material';
import React from 'react';
// @ts-ignore
import Barcode from 'react-barcode';
import BarcodeInput from 'src/components/inputs/BarcodeInput';
import DatePickerWrapper from 'src/components/inputs/DatePickerInput';
import NumberInput from 'src/components/inputs/NumberInput';
import SelectInput from 'src/components/inputs/SelectInput';
import TextFieldInput from 'src/components/inputs/TextFieldInput';
import { RCard } from 'src/components/wrappers/RCard';
import { TableColumn } from 'src/model/components/table/TableColumn';

interface Props {
	columns: (TableColumn & { placeholder?: string; helperText?: string })[];
	model?: Record<string, any>;
	onChange?: (field: string, value: any) => void;
	readonly?: boolean;
	subheader?: string;
	noCard?: boolean;
	fullWidthInputs?: boolean;
	inputsOnly?: boolean;
	requiredColumns: string[];
	fieldsToExplicitlyAllowEdits?: string[];
}

const TextFieldGroup = <T extends object>({
	columns,
	model,
	onChange,
	readonly,
	subheader,
	noCard,
	inputsOnly,
	fullWidthInputs,
	requiredColumns,
	fieldsToExplicitlyAllowEdits,
}: Props) => {
	const inputs = (
		<Grid container spacing={2}>
			{columns
				.filter((a) => !a.hideFromEdit)
				.map((column) => {
					if (!column.field) return <></>;

					const field = column.field as string;

					const isRequired = requiredColumns.includes(field);
					const isValid =
						model &&
						(!isRequired || (model[field] !== null && model[field] !== undefined));

					const isElementDisabled =
						readonly ||
						(fieldsToExplicitlyAllowEdits &&
							!fieldsToExplicitlyAllowEdits.some((editField) => editField === field));
					return (
						<>
							<Grid
								key={field!.toString()}
								item
								xs={12}
								sm={!fullWidthInputs && !column.isLargeTextField && 6}
								md={!fullWidthInputs && !column.isLargeTextField && 4}
								lg={!fullWidthInputs && !column.isLargeTextField && 3}
							>
								{column.type?.includes('date') || column.type?.includes('time') ? (
									<DatePickerWrapper
										required={requiredColumns.includes(field)}
										key={field}
										readonly={isElementDisabled}
										label={column.title as string}
										type={column.type}
										fieldName={field}
										value={model ? (model[field] as any as string) ?? '' : ''}
										onChange={(newValue) =>
											onChange && onChange(field!, newValue)
										}
									/>
								) : column.isBarcode ? (
									<BarcodeInput
										required={requiredColumns.includes(field)}
										key={field}
										readonly={isElementDisabled}
										label={column.title as string}
										type={column.type!}
										fieldName={field}
										value={model ? (model[field] as any as string) ?? '' : ''}
										onChange={(newValue) =>
											onChange && onChange(field!, newValue)
										}
									/>
								) : column.type?.includes('boolean') ? (
									<SelectInput
										fieldName={field}
										onChange={(newValue) =>
											onChange && onChange(field!, newValue === 'true')
										}
										values={[
											{ label: 'True', value: 'true' },
											{ label: 'False', value: 'false' },
										]}
										disabled={isElementDisabled}
										errored={!isValid}
										key={field}
										label={column.title as string}
										required={isRequired}
										value={model ? (model[field] as any as string) ?? '' : ''}
									/>
								) : column.type === 'numeric' || column.type === 'currency' ? (
									<NumberInput
										fieldName={field}
										disabled={isElementDisabled}
										onChange={(newValue) =>
											onChange && onChange(field!, newValue)
										}
										value={model && model[field!] ? model[field!] : null}
										errored={!isValid}
										key={field}
										label={column.title as string}
										required={isRequired}
									/>
								) : (
									<TextFieldInput
										required={isRequired}
										key={field}
										fieldName={field}
										label={column.title as string}
										onChange={(newValue) =>
											onChange && onChange(field!, newValue)
										}
										disabled={isElementDisabled}
										errored={!isValid}
										helperText={column.helperText}
										placeholder={column.placeholder}
										rows={column.isLargeTextField ? 5 : undefined}
										value={model ? (model[field] as any as string) ?? '' : ''}
									/>
								)}
							</Grid>
							{column.isBarcode && model && model[field] ? (
								<Grid
									key={field!.toString() + '_barcode'}
									item
									xs={12}
									sm={!fullWidthInputs && 6}
									md={!fullWidthInputs && 4}
									lg={!fullWidthInputs && 3}
								>
									<Barcode
										value={model[field]}
										displayValue={false}
										height={56}
										margin={0}
										width={1}
									/>
								</Grid>
							) : (
								<></>
							)}
						</>
					);
				})}
		</Grid>
	);

	if (inputsOnly) {
		return inputs;
	}

	return <RCard>{inputs}</RCard>;
};

export default TextFieldGroup;
