import Settings from '@mui/icons-material/Settings';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCapabilityCanAccessAdminAreas } from 'src/redux/selectors/capabilities';

import { useStyles } from './DrawerContents';

export const GoToAdminButton = () => {
	const { pathname } = useLocation();
	const classes = useStyles();
	const history = useHistory();
	const isAdmin = useSelector(selectCapabilityCanAccessAdminAreas);

	if (!isAdmin) return <></>;

	return (
		<List
			className={classNames(
				classes.listItem,
				pathname === '/admin' ? classes.selected : undefined,
				classes.disableOnMobile
			)}
		>
			<ListItem
				id={`listItemButton-admin`}
				button
				onClick={(): void => history.push('/admin')}
			>
				<ListItemIcon className={classes.icon}>
					<Settings />
				</ListItemIcon>
				<ListItemText primary="Admin" />
			</ListItem>
		</List>
	);
};
