import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { Views } from '../models/views';
import { fetchView } from '../thunks/views';

const initialState: ISlice<Views> = {
	isLoading: false,
	data: {},
};

const slice = createSlice({
	name: 'views',
	initialState,
	reducers: {
		// updateName(state, action: PayloadAction<string>) {
		// 	state.firstName = action.payload;
		// },
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchView.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchView.rejected, (state, action) => {
				state.isLoading = false;
				state.data[`${action.meta.arg.schema}.${action.meta.arg.view}`] = [];
			})
			.addCase(fetchView.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data[`${action.meta.arg.schema}.${action.meta.arg.view}`] = action.payload;
			}),
});

// export const {} = slice.actions;

export default slice.reducer;
