/**
 * Configuration class needed in base class.
 * The config is provided to the API client at initialization time.
 * API clients inherit from #AuthorizedApiBase and provide the config.
 */
export interface IConfig {
	getAuthToken?: () => string;
}

export class AuthorizedApiBase {
	protected readonly config: IConfig;

	protected constructor(config: IConfig) {
		this.config = config;
	}

	protected transformOptions = (options: RequestInit): Promise<RequestInit> => {
		// options.credentials = 'include';
		// options.mode = 'cors';

		// @ts-ignore
		options.headers['Authorization'] = `Bearer ${this.config.getAuthToken()}`;
		return Promise.resolve(options);
	};
}
