/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { LoadingModal } from 'src/components/loading/loadingModal/LoadingModal';
import SnackbarWrapper from 'src/components/snackbar/SnackbarWrapper';

import keycloak from '../Keycloak';
import store from '../redux/store';
import App from './App';
import { AuthWrapper } from './AuthWrapper';

const persistor = persistStore(store);

const ReduxRoot: React.FC = () => {
	return (
		<Provider store={store}>
			<ReactKeycloakProvider
				authClient={keycloak}
				LoadingComponent={<LoadingModal text={`Loading Authentication Provider`} />}
			>
				<AuthWrapper>
					<PersistGate loading={null} persistor={persistor}>
						<React.Suspense fallback={<LoadingModal text={`Loading Page`} />}>
							<Router>
								<App />
							</Router>
						</React.Suspense>
					</PersistGate>
				</AuthWrapper>
			</ReactKeycloakProvider>
			<SnackbarWrapper />
		</Provider>
	);
};

export default ReduxRoot;
