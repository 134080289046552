import CameraFront from '@mui/icons-material/CameraFront';
import CameraRear from '@mui/icons-material/CameraRear';
import CropFree from '@mui/icons-material/CropFree';
import FlashOff from '@mui/icons-material/FlashOff';
import FlashOn from '@mui/icons-material/FlashOn';
import { Alert, TextField } from '@mui/material';
import { CircularProgress, Dialog, IconButton } from '@mui/material';
import React, { useState } from 'react';
// @ts-ignore
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

interface Props {
	label: string;
	value: string;
	onChange: (event: string) => void;
	type: string;
	readonly?: boolean;
	required?: boolean;
	fieldName: string;
}

const BarcodeInput: React.FC<Props> = ({
	label,
	value,
	onChange,
	type,
	readonly,
	required,
	fieldName,
}) => {
	const isValid = !required || !!value;
	const [isFrontFacing, setIsFrontFacing] = useState(false);
	const [isFlashOn, setIsFlashOn] = useState(false);

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [stopStream, setStopStream] = useState(false);

	const dismissQrReader = () => {
		setStopStream(true);
		setTimeout(() => setIsDialogOpen(false), 0);
	};

	return (
		<>
			<TextField
				required={required}
				error={!isValid}
				aria-readonly={readonly}
				disabled={readonly}
				id={`barcode-input-${fieldName}`}
				label={label}
				value={value}
				fullWidth
				variant="outlined"
				InputLabelProps={{
					shrink: true,
				}}
				InputProps={{
					endAdornment: (
						<IconButton
							id={`button-barcode-${fieldName}`}
							disabled={readonly}
							type="submit"
							aria-label="open-barcode"
							onClick={() => setIsDialogOpen(true)}
							size="large"
						>
							<CropFree />
						</IconButton>
					),
				}}
				onChange={(event) => {
					onChange(event.target.value);
				}}
			/>
			<Dialog
				open={isDialogOpen}
				onClose={(e) => dismissQrReader()}
				aria-labelledby="form-dialog-title"
			>
				<div
					style={{
						zIndex: 999,
						position: 'absolute',
						display: 'flex',
						height: '100%',
						width: '100%',
						padding: 16,
						flexDirection: 'column',
						placeContent: 'space-between',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<IconButton
							color={!isFlashOn ? 'default' : 'primary'}
							onClick={() => setIsFlashOn(!isFlashOn)}
							size="large"
						>
							{!isFlashOn ? <FlashOff /> : <FlashOn />}
						</IconButton>
						<IconButton onClick={() => setIsFrontFacing(!isFrontFacing)} size="large">
							{!isFrontFacing ? <CameraRear /> : <CameraFront />}
						</IconButton>
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flex: 1,
							padding: 48,
						}}
					>
						<div
							style={{
								width: '100%',
								height: '100%',
								borderWidth: 5,
								borderStyle: 'solid',
								borderRadius: 12,
							}}
						/>
					</div>
					<div>
						<Alert icon={<CircularProgress size={18} />} severity="info">
							Looking for a barcode ...
						</Alert>
					</div>
				</div>
				<BarcodeScannerComponent
					torch={isFlashOn}
					facingMode={isFrontFacing ? 'user' : 'environment'}
					onUpdate={(err, result) => {
						if (result) {
							// @ts-ignore
							onChange(result.text);
							dismissQrReader();
						} else onChange('');
					}}
					onError={(error: any) => {
						if (error.name === 'NotAllowedError') {
							console.error(error);
						}
					}}
					stopStream={stopStream}
				/>
			</Dialog>
		</>
	);
};

export default BarcodeInput;
