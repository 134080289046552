import { createSlice } from '@reduxjs/toolkit';

import { ISlice } from '../models';
import { AccountAlerts } from '../models/accountAlerts';
import { fetchAccountAlerts } from '../thunks/accountAlerts';

const initialState: ISlice<AccountAlerts> = {
	isLoading: false,
	data: {
		accountAlerts: [],
	},
};

const slice = createSlice({
	name: 'accountAlerts',
	initialState,
	reducers: {
		// updateName(state, action: PayloadAction<string>) {
		// 	state.firstName = action.payload;
		// },
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchAccountAlerts.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchAccountAlerts.rejected, (state, action) => {
				state.isLoading = false;
				state.data.accountAlerts = [];
			})
			.addCase(fetchAccountAlerts.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data.accountAlerts = action.payload;
			}),
});

// export const {} = slice.actions;

export default slice.reducer;
