import { createSlice } from '@reduxjs/toolkit';
import { DatabaseDefinition } from 'src/api/api';

import { ISlice } from '../models';
import {
	fetchDatabaseDefinition,
	fetchDatabaseDefinitionAndRefreshCache,
} from '../thunks/databaseDefinition';
import { addColumnToRow, updateColumnOnRow } from '../thunks/table';

const initialState: ISlice<DatabaseDefinition | null> = {
	isLoading: true,
	data: null,
};

const slice = createSlice({
	name: 'databaseDefinition',
	initialState,
	reducers: {
		// updateName(state, action: PayloadAction<string>) {
		// 	state.firstName = action.payload;
		// },
	},
	extraReducers: (builder) =>
		builder
			.addCase(fetchDatabaseDefinition.pending, (state, action) => {
				state.isLoading = true;
				if (action.meta.arg.dbguid !== state.data?.dbGuid) {
					state.data = null;
				}
			})
			.addCase(fetchDatabaseDefinition.rejected, (state) => {
				state.isLoading = false;
				state.data = null;
			})
			.addCase(fetchDatabaseDefinition.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})

			.addCase(fetchDatabaseDefinitionAndRefreshCache.pending, (state, action) => {
				state.isLoading = true;
				if (action.meta.arg.dbguid !== state.data?.dbGuid) {
					state.data = null;
				}
			})
			.addCase(fetchDatabaseDefinitionAndRefreshCache.rejected, (state) => {
				state.isLoading = false;
				state.data = null;
			})
			.addCase(fetchDatabaseDefinitionAndRefreshCache.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})

			.addCase(addColumnToRow.fulfilled, (state, action) => {
				for (let index = 0; index < state.data!.tables!.length; index++) {
					const element = state.data!.tables![index];
					if (
						element.category === action.meta.arg.request.schema &&
						element.name === action.meta.arg.request.table
					) {
						element.fields = [
							...(element.fields || []),
							{
								name: action.meta.arg.request.newColumnName,
								isNullable: action.meta.arg.request.isNullable,
								type: action.meta.arg.request.sqlDataType,
								stringMaxLength: action.meta.arg.request.maxLength,
							},
						];
					}
				}
			})

			.addCase(updateColumnOnRow.fulfilled, (state, action) => {
				if (!action.meta.arg.request.columnDefinition) return;

				for (let i = 0; i < state.data!.tables!.length; i++) {
					const element = state.data!.tables![i];
					if (!element.fields) continue;

					if (
						element.category === action.meta.arg.request.schema &&
						element.name === action.meta.arg.request.table
					) {
						if (!action.meta.arg.request.columnDefinition.name) {
							const newColumn = { ...action.meta.arg.request.columnDefinition };
							newColumn.name = newColumn.newName;
							delete newColumn.newName;
							element.fields = [...element.fields, newColumn];
							return;
						}

						for (let j = 0; j < element.fields!.length; j++) {
							const column = element.fields![j];
							if (column.name === action.meta.arg.request.columnDefinition.name) {
								const newColumn = { ...action.meta.arg.request.columnDefinition };
								newColumn.name = newColumn.newName ?? newColumn.name;
								delete newColumn.newName;
								element.fields![j] = newColumn;
								break;
							}
						}
						break;
					}
				}
			}),
});

// export const {} = slice.actions;

export default slice.reducer;
