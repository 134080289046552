import {
	Card,
	CardActionArea,
	CardContent,
	LinearProgress,
	Theme,
	Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

import { ServerLogChildErrors } from './ServerLogChildErrors';

interface Props {
	title?: string;
	subTitle?: string;
	className?: string;
	onClick?: () => void;
	loading?: boolean;
	dense?: boolean;
	noPad?: boolean;
	childGridContainerSpacing?: number;
}

export const RCard: React.FC<Props> = ({
	children,
	title,
	subTitle,
	className,
	onClick,
	loading,
	dense,
	childGridContainerSpacing,
	noPad,
}) => {
	const classes = useStyles(childGridContainerSpacing);
	const content = (
		<>
			{title && <Typography variant="h4">{title}</Typography>}
			{subTitle && (
				<Typography variant="h6" gutterBottom>
					{subTitle}
				</Typography>
			)}
			{children}
		</>
	);

	return (
		<Card className={classNames(classes.card, className ?? '')} elevation={0}>
			{loading && <LinearProgress />}
			<ServerLogChildErrors>
				{onClick ? (
					<CardActionArea
						className={
							noPad
								? classes.noPadCardContent
								: dense
								? classes.denseCardContent
								: classes.cardContent
						}
						onClick={onClick}
					>
						{content}
					</CardActionArea>
				) : (
					<CardContent
						className={
							noPad
								? classes.noPadCardContent
								: dense
								? classes.denseCardContent
								: classes.cardContent
						}
					>
						{content}
					</CardContent>
				)}
			</ServerLogChildErrors>
		</Card>
	);
};

const useStyles = (childGridContainerSpacing?: number) =>
	makeStyles((theme: Theme) =>
		createStyles({
			card: {
				// marginTop: theme.spacing(4),
				borderColor: theme.palette.grey[300],
				borderWidth: 1,
				borderStyle: 'solid',
			},
			cardContent: {
				marginTop: theme.spacing(1),
				padding: theme.spacing(3 + (childGridContainerSpacing ?? 0)),
				[theme.breakpoints.down('md')]: {
					padding: theme.spacing(2 + (childGridContainerSpacing ?? 0)),
				},
			},
			denseCardContent: {
				padding: theme.spacing(1 + (childGridContainerSpacing ?? 0)),
			},
			noPadCardContent: {
				padding: '0!important',
			},
		})
	)();
