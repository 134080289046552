import { Grid, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatabases } from 'src/redux/selectors/databases';
import { selectDatabase } from 'src/redux/thunks/databases';

import { BackgroundUi } from './BackgroundUi';

export const DatabasePicker = () => {
	const databases = useSelector(selectDatabases);
	const theme = useTheme();
	const dispatch = useDispatch();
	const { keycloak } = useKeycloak();

	return (
        <BackgroundUi>
			<>
				<Grid
					container
					justifyContent="center"
					style={{
						backgroundColor: theme.palette.background.paper,
					}}
				>
					<Grid item xs={12}>
						<Typography variant="h4">
							Welcome {keycloak?.tokenParsed?.given_name}
						</Typography>
						<Typography variant="body1">
							Before we continue, please select a database to continue.
						</Typography>
						<List component="nav">
							{databases?.map((d) => (
								<ListItem
									button
									key={d.id}
									onClick={() => dispatch(selectDatabase(d.id!))}
								>
									<ListItemText primary={d.name} />
								</ListItem>
							))}
						</List>
					</Grid>
				</Grid>
			</>
		</BackgroundUi>
    );
};
