import { createAsyncThunk } from '@reduxjs/toolkit';
import { RapydlyApi } from 'src/api/RapydlyApi';

import { CurrentRow } from '../models/currentRow';

export const fetchRow = createAsyncThunk(
	'row/fetch',
	async ({
		schema,
		table,
		primaryKeys,
		client,
	}: {
		schema: string;
		table: string;
		primaryKeys: Record<string, any>;
		client: RapydlyApi;
	}) => {
		const response = (await client.tables_GetRow({
			schema: schema,
			table: table,
			primaryIds: primaryKeys,
		})) as any as CurrentRow;
		return response;
	}
);
