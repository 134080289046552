import { createAsyncThunk } from '@reduxjs/toolkit';
import { RunFunctionRequest } from 'src/api/api';
import { RapydlyApi } from 'src/api/RapydlyApi';

export const runFunction = createAsyncThunk(
	'function/run',
	async ({ client, request }: { client: RapydlyApi; request: RunFunctionRequest }) => {
		const response = await client.functions_Run(request);
		return response;
	}
);
