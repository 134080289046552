import { CircularProgress, Typography, useTheme } from '@mui/material';
import React from 'react';
import { AppLogo } from 'src/components/drawer/AppLogo';

export const LoadingModal = ({ text }: { text?: string }) => {
	const theme = useTheme();

	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				background: theme.palette.background.default,
				zIndex: 9999,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						textAlign: 'center',
						height: '100%',
						flex: 1,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<div>
						<CircularProgress />
						<Typography>{text}</Typography>
					</div>
				</div>
				<div style={{ marginBottom: 32 }}>
					<AppLogo />
				</div>
			</div>
		</div>
	);
};
