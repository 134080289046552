import { createAsyncThunk } from '@reduxjs/toolkit';
import { RunStoredProcedureRequest } from 'src/api/api';
import { RapydlyApi } from 'src/api/RapydlyApi';

export const runStoredProcedure = createAsyncThunk(
	'sp/run',
	async ({ client, request }: { client: RapydlyApi; request: RunStoredProcedureRequest }) => {
		const response = await client.storedProcedures_Run(request);
		return response;
	}
);
