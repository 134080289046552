import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';

import { AppLogoSection } from './AppLogoSection';
import { CustomPagesList } from './CustomPagesList';
import { DatabaseName } from './DatabaseName';
import { FilterTextTextBox } from './FilterTextTextBox';
import { Functions } from './Functions';
import { GoToAdminButton } from './GoToAdminButton';
import { GoToHomeButton } from './GoToHomeButton';
import { GoToOrganisationAdminButton } from './GoToOrganisationAdminButton';
import { GoToOverviewButton } from './GoToOverviewButton';
import { GoToReportsButton } from './GoToReportsButton';
import { StoredProcedures } from './StoredProcedures';
import { Tables } from './Tables';
import { TenantPicker } from './TenantPicker';
import { Views } from './Views';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawerHeader: theme.mixins.toolbar,
		schema: {
			fontWeight: 'bold',
			padding: 0,
			textTransform: 'uppercase',
			background: '#fafafa00',
			backdropFilter: 'blur(5px)',
		},
		primary: {
			fontWeight: 'bold',
		},
		table: {
			paddingLeft: theme.spacing(4),
		},
		icon: {
			minWidth: 40,
		},
		selected: {
			color: theme.palette.primary.contrastText,
			'& > div': {
				background: theme.palette.primary.dark,
			},
			'& svg': {
				fill: theme.palette.primary.contrastText,
			},
		},
		selectedItem: {
			color: theme.palette.primary.contrastText,
			background: theme.palette.primary.dark,
			'& > div': {
				color: theme.palette.primary.contrastText,
			},
		},
		disableOnMobile: {
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
		custompageItems: {
			marginBottom: 4,
		},
		logoWrapper: {
			minHeight: 100,
			maxHeight: 150,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: 64,
			marginBottom: 64,
		},
		logo: {
			maxWidth: '100%',
			maxHeight: 150,
		},
		listItem: {
			'& > div': {
				borderRadius: 5,
			},
			'& > div:hover': {
				background: theme.palette.primary.light,
				color: theme.palette.primary.contrastText,
				'& svg': {
					fill: theme.palette.primary.contrastText,
				},
			},
		},
	})
);

export const PageDrawer: React.FC = () => {
	return (
		<>
			<AppLogoSection />
			<DatabaseName />
			<TenantPicker />
			<GoToHomeButton />
			<GoToReportsButton />
			<GoToOverviewButton />
			<GoToAdminButton />
			<GoToOrganisationAdminButton />
			<CustomPagesList />
			<FilterTextTextBox />
			<StoredProcedures />
			<Functions />
			<Views />
			<Tables />
		</>
	);
};
