import React from 'react';
import { Helmet } from 'react-helmet';

export const RHelmet = ({ title }: HelmetProps) => {
	return (
		<Helmet>
			<title>Rapydly - {title ?? 'Welcome'}</title>
		</Helmet>
	);
};
interface HelmetProps {
	title?: string;
}
