import { useKeycloak } from '@react-keycloak/web';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { serverUrl } from 'src/clientConfig';
import { selectSelectedDatabaseId } from 'src/redux/selectors/preferencesLocal';

import { RapydlyApi } from './RapydlyApi';

const RapydlyApiNoTenantContext = React.createContext<RapydlyApi>(
	new RapydlyApi(serverUrl, '', () => Promise.resolve())
);

export const RapydlyApiNoTenantProvider: React.FC = ({ children }) => {
	const selectedDatabaseId = useSelector(selectSelectedDatabaseId);
	const { keycloak } = useKeycloak();

	const rapydlyApi = useMemo(
		() =>
			new RapydlyApi(
				serverUrl,
				selectedDatabaseId ?? '',
				async () => await keycloak.logout(),
				'',
				() => keycloak.token ?? '',
				undefined
			),
		[selectedDatabaseId, keycloak]
	);

	return (
		<RapydlyApiNoTenantContext.Provider value={rapydlyApi}>
			{children}
		</RapydlyApiNoTenantContext.Provider>
	);
};

export const useRapydlyApiNoTenant = () => useContext(RapydlyApiNoTenantContext);
