import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Tooltip,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { NodeDefinition } from 'src/api/api';
import { selectFilterText } from 'src/redux/selectors/appState';
import {
	selectCapabilityCanAccessAdminAreas,
	selectCapabilityCanViewAllTables,
} from 'src/redux/selectors/capabilities';
import { selectTableDefinitions } from 'src/redux/selectors/databaseDefinition';
import { selectAppPreferences, selectTablePreferencesAll } from 'src/redux/selectors/preferences';

import { useStyles } from './DrawerContents';

export const Tables = () => {
	const tables = useSelector(selectTableDefinitions);
	const filterText = useSelector(selectFilterText);
	const appPreferences = useSelector(selectAppPreferences);
	const tablePreferences = useSelector(selectTablePreferencesAll);
	const classes = useStyles();
	const { pathname } = useLocation();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const isAdmin = useSelector(selectCapabilityCanAccessAdminAreas);
	const canViewTablesList = useSelector(selectCapabilityCanViewAllTables);

	const tableDict = (tables ?? [])
		.filter((a) => appPreferences.tablesToHide?.includes(`${a.category}.${a.name}`) !== true)
		.reduce((all, a) => {
			all[a.category!] = all[a.category!] ? [...all[a.category!], a] : [a];
			return all;
		}, {} as Record<string, NodeDefinition[]>);

	const getPath = (node: NodeDefinition) => `/${node.category!}/${node.name!}`;

	if (!canViewTablesList) return <></>;

	return Object.keys(tableDict).length === 0 ? (
		<></>
	) : (
		<>
			<List>
				<ListItem id={`listItemButton-table-hideshow`} button>
					<ListItemText className={classes.primary} onClick={() => setIsOpen((a) => !a)}>
						Tables{' '}
						{isOpen ? (
							<ExpandLessIcon style={{ float: 'right' }} />
						) : (
							<ExpandMoreIcon style={{ float: 'right' }} />
						)}
					</ListItemText>
				</ListItem>
				<Collapse in={isOpen}>
					{Object.keys(tableDict)
						.sort()
						.map((schema) => (
							<div key={schema}>
								<ListSubheader color="inherit" className={classes.schema}>
									{appPreferences.schemaDisplayNames &&
									appPreferences.schemaDisplayNames[schema]
										? appPreferences.schemaDisplayNames[schema]
										: schema}
								</ListSubheader>
								{tableDict[schema]
									.sort()
									.filter(
										(a) =>
											a &&
											a.name &&
											(!filterText ||
												a.name
													?.toLowerCase()
													.indexOf(filterText?.toLowerCase().trim()) > -1)
									)
									.map((p) => (
										<ListItem
											id={`listItemButton-table-${schema}-${p.name}`}
											className={
												pathname.startsWith(getPath(p))
													? classes.selected
													: undefined
											}
											key={getPath(p)}
											button
											onClick={(): void => history.push(getPath(p))}
										>
											<ListItemText
												disableTypography
												className={classes.table}
												primary={
													tablePreferences &&
													tablePreferences[`${schema}.${p.name}`] &&
													tablePreferences[`${schema}.${p.name}`]
														.displayName
														? tablePreferences[`${schema}.${p.name}`]
																.displayName
														: p.name
												}
											/>
											{appPreferences.tablesToHide?.includes(
												`${p.category}.${p.name}`
											) === true && (
												<Tooltip title="This table is hidden for non-admin users">
													<ListItemIcon>
														<VisibilityOff />
													</ListItemIcon>
												</Tooltip>
											)}
										</ListItem>
									))}
							</div>
						))}
				</Collapse>
			</List>
		</>
	);
};
