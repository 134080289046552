import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { LoadingModal } from 'src/components/loading/loadingModal/LoadingModal';

export const AuthWrapper: React.FC = ({ children }) => {
	const { keycloak, initialized } = useKeycloak();

	if (!initialized) {
		return <LoadingModal text="Authenticating" />;
	} else if (!keycloak.authenticated) {
		keycloak.login();
		return <LoadingModal text="Authenticating" />;
	} else {
		return <>{children}</>;
	}
};
